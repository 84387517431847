import React, { Component } from 'react';
import 'react-router-dom';
import ReactPlayer from 'react-player';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { getCurrentWalletConnected, getMyPasses } from '../util/interact.js';

export default class MyPoppablePasses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      currentImageUrl: '',
      images: [],
      passes: [],
    };

    this.routeChange = this.routeChange.bind(this);
  }

  routeChange() {
    let path = `/`;
    this.props.history.push(path);
  }

  getFullRes(image) {
    if (image.includes('.mp4')) {
      return image;
    } else {
      return image.substring(0, image.length - 4) + '.png';
    }
  }

  isHiddenVideo(image) {
    if (image.includes('.mp4')) {
      return '';
    } else {
      return 'ElementHidden';
    }
  }
  isHiddenImage(image) {
    if (!image.includes('.mp4')) {
      return '';
    } else {
      return 'ElementHidden';
    }
  }

  getTokenNumber(fileName) {
    let myPasses = this.state.passes;
    for (const element of myPasses) {
      if (fileName == element.name) {
        let tokenNameID = 'Poppable ' + element.token_id;
        return tokenNameID;
      }
    }
  }

  async componentDidMount() {
    const { address } = await getCurrentWalletConnected();

    //TESTING
    // const address = '0x263436b572a205397ae127b29b777a846288bd88'; //two sky blue unpoppable

    if (address) {
      const mypassesResponse = await getMyPasses(address);

      // console.log(mypassesResponse);

      var myPasses = [];
      if (mypassesResponse) {
        myPasses = mypassesResponse.data;
      }

      var imagesData = [];

      if (myPasses.length > 0) {
        for (const element of myPasses) {
          var imageURL = element.name;
          imagesData.push(imageURL);
        }

        this.setState({
          images: imagesData,
          passes: mypassesResponse.data,
        });
      }
    }
  }

  render() {
    const { isOpen } = this.state;

    return (
      <div className="MyPassesContent z-depth-1 scrollspy">
        <div className="MyPoppablePasseslSection">
          <div className="MyPoppablePassesContent">
            <div className="row">
              <div className="col s2">
                <a
                  onClick={this.routeChange}
                  className="btn-floating btn-large waves-effect waves-light blue lighten-3"
                >
                  <i className="material-icons">arrow_back</i>
                </a>
              </div>
              <div className="col s10"></div>
              <h2 className="MyPassesTitle">My Poppable Passes</h2>
            </div>
            <div className="row">
              <div>
                {this.state.images.map((image, ix) => (
                  <div key={ix}>
                    <div className="col s12 m3">
                      <div className="card">
                        <div className="card-image">
                          <div className={this.isHiddenImage(image)}>
                            <img
                              className="responsive-img ZoomedImage"
                              onClick={() =>
                                this.setState({
                                  isOpen: true,
                                  currentImageUrl: image,
                                })
                              }
                              src={image}
                            />
                          </div>
                          <div className={this.isHiddenVideo(image)}>
                            <div className="Player">
                              <ReactPlayer
                                url={image}
                                width="100%"
                                height="100%"
                                playing={true}
                                loop={true}
                                playsinline={true}
                                controls={false}
                                muted={false}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card-content">
                          <p>{this.getTokenNumber(image)}</p>
                        </div>
                        <div className="card-action">
                          <a
                            href={this.getFullRes(image)}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            High Resolution
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {isOpen && (
          <Lightbox
            enableZoom={true}
            onCloseRequest={() => this.setState({ isOpen: false })}
            mainSrc={this.state.currentImageUrl}
          />
        )}
      </div>
    );
  }
}
