import React, { Component, Link } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import 'materialize-css/dist/css/materialize.min.css';
import '.././App.css';

class Sidebar extends Component {
  componentDidMount() {
    var elem = document.querySelector('.sidenav');
    var instance = M.Sidenav.init(elem, {
      edge: 'left',
      inDuration: 250,
    });
  }

  render() {
    return (
      <div className="SideBar">
        <ul id="slide-out" className="sidenav">
          <li />
          <li>
            <a href="#" className="sidenav-close">
              Poppables
            </a>
          </li>
          <li>
            <a href="https://discord.gg/t55m4KY77k" className="sidenav-close">
              <i className="fab fa-discord fa-2x"></i>Join us on Discord
            </a>
          </li>

          <li>
            <a href="https://twitter.com/PoppableProject" className="sidenav-close">
              <i className="fab fa-twitter fa-2x"></i>Join us on Twitter
            </a>
          </li>

          <li>
            <a href="https://www.instagram.com/poppableprojects/" className="sidenav-close">
              <i className="fab fa-instagram fa-2x"></i>Join us on Instagram
            </a>
          </li>

          <li>
            <a href="https://opensea.io/PoppableProjects?tab=created" className="sidenav-close">
              Official OpenSea Account
            </a>
          </li>
          <li>
            <div className="divider" />
          </li>
          <li>
            <a href="#Home" className="sidenav-close">
              Home
            </a>
          </li>
          <li>
            <a href="/guidelines" className="sidenav-close">
              How to collect the Poppables?
            </a>
          </li>

          {/* <li>
            <a href="#sectionPopping" className="sidenav-close">
              The Poppable Station Phase 2
            </a>
          </li> */}

          {/* <li>
            <a href="/poppingstationphase1" className="sidenav-close">
              The Poppable Station Phase 1
            </a>
          </li> */}

          <li>
            <a href="#sectionPopping" className="sidenav-close">
              The Poppable Station
            </a>
          </li>

          <li>
            <a href="#RoadMapSection" className="sidenav-close">
              The Road Map
            </a>
          </li>
          <li>
            <a href="/mypoppablepasses" className="sidenav-close">
              My Poppable Passes
            </a>
          </li>
          <li>
            <a href="/legal" className="sidenav-close">
              Legal
            </a>
          </li>
        </ul>
        {/* <a href="#" data-target="slide-out" className="sidenav-trigger">
          <i className="material-icons small Hamburger">menu</i>
        </a> */}
      </div>
    );
  }
}

export default Sidebar;
