import React from 'react';
import '.././App.css';
import { useHistory } from 'react-router-dom';

export const CollectorGuidelines = () => {
  let history = useHistory();
  return (
    <div className="LegalSection">
      <div className="LegalContent">
        <a onClick={() => history.goBack()} class="btn-floating btn-large waves-effect waves-light blue lighten-3">
          <i class="material-icons">arrow_back</i>
        </a>
        <div className="ImageCenter center-align">
          <img className="responsive-img" src="images/poppables-width300.png" alt="logo" />
        </div>
        <h2 className="H2 center-align">Collector Guidelines</h2>
        <p>
          Hello, Poppables insider.
          <br />
          Congratulations! You’ve minted your Poppable(s). You’re wondering, “What do I do now?” <br />
          There are two games that you will be able to play with Poppables:
        </p>
        <ul className="ListItems">
          <li className="Ul">
            The first is the monthly collecting game. In it, you collect matching pairs, different, or rare combinations
            and sets of Poppables. You can start playing this game already!
          </li>
          <li className="Ul">The second game will be a weekly Texas Hold ‘Em style poker game.</li>
        </ul>
        <p>
          Each game will have its own leaderboard. The leaderboards will track which collector has what Poppable[s], the
          values and completion statuses of the different collections, and—someday—reward possibilities.
          <br />
          Your Poppables journey starts here. This is the roadmap; choose YOUR Poppables destination.
        </p>

        <h2 className="H2 center-align">Anatomy of a Poppable</h2>
        <p>Before you start collecting, let’s take a look at the parts that comprise a Poppable.</p>
        <img className="GuideImage" src="images/guide1.jpg" alt="guide image2" />
        <p>Set Guidelines</p>
        <ul className="ListItems">
          <li className="Ul">
            <b>Set of 3: The Card Players</b>
            <p>
              <i>How to collect this set:</i> This is the smallest set that can be completed. Acquiring both the left
              and right sides of The Card Players Injection backgrounds along with the corresponding Impression
              background completes this set.
            </p>
            <img className="GuideImage" src="images/guide2.jpg" alt="guide image2" />
          </li>
          <li className="Ul">
            <b>Set of 12: Unique Participants </b>
            <p>
              <i>How to collect this set:</i> The participant set is made up of the 48 participants highlighted in the
              project. Collect 12 Poppables that each have 4 unique non repeating coins in each of the four corners to
              complete this set.
            </p>
          </li>
          <li className="Ul">
            <b>Set of 21: Every Background </b>
            <p>
              <i>How to collect this set:</i> Collect all 11 Injection and 10 Impression backgrounds to complete this
              set.
            </p>
          </li>
          <li className="Ul">
            <b>Set of 25: Every Color</b>
            <p>
              <i>How to collect this set:</i> Collect one of each of the 25 bubble colors to complete this set.
            </p>
          </li>
          <li className="Ul">
            <b> Set of 26: Every UNPOPPABLE</b>
            <p>
              <i>How to collect this set:</i> Want to collect the most difficult and rare set? Some Poppables are
              UNPOPPABLE! Collect one of each color of the unpoppable Poppables plus <b>the elusive clear Poppable</b>
              to complete this set.
            </p>
          </li>
        </ul>

        <h2 className="H2 center-align">Methods of Collection</h2>
        <p>Collection possibilities are up to you, the collector.</p>
        <p>
          <b>Splat style color and background</b>
        </p>
        <p>
          You can form collections based on each of the base sets listed above, or you can make a more valuable set by
          layering one of the traits on top of the base set.
        </p>
        <p>
          We’ll use <b>The Card Players set</b> to demonstrate:
        </p>
        <ul className="ListItems">
          <li className="Ul">
            Add a layer of complexity by getting all three in the set, one with <b>each of the three splat styles.</b>
            <img className="GuideImage" src="images/guide3.jpg" alt="guide image3" />
          </li>
          <li className="Ul">
            Try getting the complete set in all the <b>same splat style.</b>
            <img className="GuideImage" src="images/guide4.jpg" alt="guide image4" />
          </li>
          <li className="Ul">
            Or get the complete set with the <b>same color bubble.</b>
            <img className="GuideImage" src="images/guide5.jpg" alt="guide image5" />
          </li>
          <p>Ambitious?</p>
          <li className="Ul">
            Try getting all the same set in the <b>same color with each of the three splat styles.</b>
            <img className="GuideImage" src="images/guide6.jpg" alt="guide image6" />
          </li>
          <li className="Ul">
            Or get the complete set with the{' '}
            <b>
              same color and the <u>same splat style.</u>
            </b>
            <img className="GuideImage" src="images/guide7.jpg" alt="guide image7" />
          </li>
          <li className="Ul">
            Challenge yourself by collecting the background set, in which each bubble has the same color or splat style.
          </li>
          <li className="Ul">Or do the same for the participant set.</li>
        </ul>
        <p>The possibilities are endless—they lie in the collector’s imagination.</p>
        <p>
          Each of these traits adds a layer of collectability to the base Poppable sets. Let creativity feed your
          ambition!
        </p>

        <h2 className="H2 center-align">Poppable Rarities</h2>
        <p>
          Instead of completing sets or in addition to doing so, some collectors may wish to hunt for rare Poppables.{' '}
        </p>
        <p>
          <b>What are rare Poppables?</b>
        </p>
        <p>Here are several types of rare Poppables:</p>
        <ul className="ListItems">
          <li className="Ul">
            <b>The Participant set</b> and <b>the UNPOPPABLE set</b> are rare collections.
          </li>
          <li className="Ul">
            However, the colors themselves vary in their level of rarity.
            <br />
            Is green rarer? Chrome?
            <br />
            Stay tuned at{' '}
            <a href="https://www.poppables.io">
              <i>Poppables</i>
            </a>{' '}
            to find out more.
          </li>
          <li className="Ul">
            Some Poppables may have TWO of the same participants on the corner coins<b>—a double.</b>
          </li>
          <li className="Ul">
            Even more remarkable, some Poppables may have three of the same participants on the corner coins
            <b>—a triple.</b>
          </li>
          <li className="Ul">
            More elusive still is the <b>double-double</b>, which has a double of two participants in the corner coins.{' '}
          </li>
        </ul>
        <p>Examples of Rare Poppables.</p>
        <img className="GuideImage" src="images/guide8.jpg" alt="guide image8" />
        <p>
          NOTE: Rare Poppables may form part of a set. However, their rarity is a quality separate from how they form
          part of a collection.
        </p>
      </div>
    </div>
  );
};

export default CollectorGuidelines;
