import axios from 'axios';

require('dotenv').config();

//QA
// const alchemyKey = process.env.REACT_APP_ALCHEMYAPI_RINKEBY_KEY;
// const contractAddress = '0xBfE46e76369e8528fe5B5B01c9eAc2244850BCe6';
// const chain = 'rinkeby';
// //MORALIS QA
// const serverUrl = 'https://unomqunu0sto.usemoralis.com:2053/server';
// const appId = 'ywgFSaBmnhUHgBR1Az8MawxP2m4mD6kWs9M1uaXN';
//old contract with many poppbles rinkeby 0x9CD9278A5208167877e6ca0e16c312BF8D986a97
//https://docs.moralis.io/moralis-server/web3-sdk/token

//PROD
const alchemyKey = process.env.REACT_APP_ALCHEMYAPI_MAINNET_KEY;
const contractAddress = '0xa9b3a94a276e295878a3dd6d0854b116406b81f0';
const chain = 'mainnet';
//MORALIS PROD
const serverUrl = 'https://9tqtf5ytcps6.usemoralis.com:2053/server';
const appId = 'zJ6qHkeFgRnGRjZXT16vT0RZmH36EyijUKUgBDh1';

const contractABI = require('../ethereum/artifacts/src/ethereum/contracts/Poppables.sol/Poppables.json');
const { createAlchemyWeb3 } = require('@alch/alchemy-web3');
const web3 = createAlchemyWeb3(alchemyKey);
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');
const Moralis = require('moralis');
Moralis.start({ serverUrl, appId });

const colorMap = new Map();

export const poppableContract = new web3.eth.Contract(contractABI.abi, contractAddress);

export const savePoppedImage = async (poppingInfo) => {
  const response = await axios.post('/api/pop', {
    token_id: poppingInfo.token_id,
    realTokenID: poppingInfo.realTokenID,
    token_color: poppingInfo.token_color,
    wallet_address: poppingInfo.wallet_address,
  });

  return response;
};

export const getMyPasses = async (address) => {
  //TESTING
  // address = '0xbaaabce9d8b6e0e7b26e107f33ddfc7bd582e301';

  const mypassesResponse = await axios.post('/api/getMyPasses', {
    wallet_address: address,
  });

  if (mypassesResponse) {
    return mypassesResponse;
  } else {
    return 'NOPASSES';
  }
};

export const mintSaveUser = async () => {
  let walletAddress;
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_accounts',
      });
      if (addressArray.length > 0) {
        walletAddress = addressArray[0];
      }
    } catch (err) {
      walletAddress = '';
    }
  }
  // console.log('React wallet address:', walletAddress);
  await axios.post('/api/users', {
    walletAddress: walletAddress,
  });
};

export const getParticipant = async (walletAddress) => {
  // console.log('Calling interact getParticipant');
  const res = await axios.get('/api/user', {
    params: {
      walletAddress: walletAddress,
    },
  });

  // console.log('Interact1: ', res.data.qty1);
  // console.log('Interact2: ', res.data.qty2);

  return { qty1: res.data.qty1, qty2: res.data.qty2 };
};

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      const obj = {
        alertMessage: 'Wallet is connected',
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: '',
        alertMessage: err.message,
      };
    }
  } else {
    return {
      address: '',
      alertMessage: 'You must install Metamask, a virtual Ethereum wallet, in your browser.',
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_accounts',
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: '👆🏽 Write a message in the text-field above.',
        };
      } else {
        return {
          address: '',
          status: '🦊 Connect to Metamask using the top right button.',
        };
      }
    } catch (err) {
      return {
        address: '',
        status: '😥 ' + err.message,
      };
    }
  } else {
    return {
      address: '',
      status: (
        <span>
          <p>
            {' '}
            🦊{' '}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getAllContractTokens = async () => {
  const options = {
    address: contractAddress,
    chain: chain,
  };

  const NFTs = await Moralis.Web3API.token.getAllTokenIds(options);
  const totalNumberOfNFTs = NFTs.total;
  // console.log('Total contract  NFTs', totalNumberOfNFTs);

  return totalNumberOfNFTs;
};

export const getCurrentUserPoppables = async (address) => {
  if (colorMap.size == 0) {
    const status = await initiateColorMap();
  }

  //TESTING REMOVE and use default address
  // var testingAddress = '0x09b7e5113a681c7a748ac2720d705d38974aa54a'; //many
  // var testingAddress = '0xba855ad3608d3428df17a5cd4a67a8a37fb71702'; //black
  // var testingAddress = '0xc87ce9394c6609474d29e7d41b23b0721e1cdfa3';
  // address = '0x09b7e5113a681c7a748ac2720d705d38974aa54a';
  // address = '0xdf5cbae73ac7848361c6ed6a2978976d2182d943';
  // address = '0xbaaabce9d8b6e0e7b26e107f33ddfc7bd582e301';

  let bubbleInfo = [];
  if (address) {
    const options = {
      chain: chain,
      address: address,
      token_addresses: contractAddress,
    };

    let nfts = await Moralis.Web3API.account.getNFTs(options);

    let totalNFTs = nfts.total;
    let fileteredNFTs = [];

    if (totalNFTs > 500) {
      let currentShift = 500;

      while (totalNFTs > 0) {
        let currentFileteredNFTs = nfts.result.filter(function (item) {
          return item.name == 'Poppables';
        });

        for (const nft of currentFileteredNFTs) {
          fileteredNFTs.push(nft);
        }

        totalNFTs = totalNFTs - 500;
        if (totalNFTs > 0) {
          const partialoptions = {
            chain: 'mainnet',
            address: address,
            offset: currentShift,
          };
          nfts = await Moralis.Web3API.account.getNFTs(partialoptions);
          currentShift = currentShift + 500;
        }
      }
    } else {
      fileteredNFTs = nfts.result.filter(function (item) {
        return item.name == 'Poppables';
      });
    }

    let sortedNFTs = fileteredNFTs.sort(function (a, b) {
      return parseInt(a.token_id) - parseInt(b.token_id);
    });

    const usedTokensResponse = await axios.post('/api/getUsedTokens', {
      wallet_address: address,
    });

    var usedTokens = [];
    if (usedTokensResponse) {
      usedTokens = usedTokensResponse.data;
    }

    var regex = /(\d+)(.json)/;
    // console.log('Debug point: ', sortedNFTs);
    for (const element of sortedNFTs) {
      let token_id = element.token_id;
      let token_uri = element.token_uri;

      let realTokenID = token_uri.match(regex)[1];
      let token_color = colorMap.get(Number(realTokenID));

      let existingNumber = isNumberExists(token_id, usedTokens);
      //token exists
      if (existingNumber == -1) {
        bubbleInfo.push({
          token_id: token_id,
          real_token_id: realTokenID,
          token_color: token_color,
        });
      }
    }
  }

  return bubbleInfo;
};

function isNumberExists(number, usedTokens) {
  for (const token of usedTokens) {
    if (Number(number) == Number(token)) return token;
  }

  return -1;
}

export const mintNFT = async (mintType, ether) => {
  // console.log('Radio: ', rselected);
  // console.log('Ether: ', ether);
  // console.log('Gift', gift);

  //CHEK FOR ONE USER FOR GIFTS
  var proceed = true;
  if (mintType == 'GIFTNFT') {
    const isUserExistsResponse = await axios.post('/api/isUserExist', {
      wallet_address: window.ethereum.selectedAddress,
    });

    if (isUserExistsResponse.data) {
      proceed = false;
    }
  }

  if (!proceed) {
    return {
      success: false,
    };
  } else {
    let etherAmount;
    let totalAmount;

    let pAmount = 0;
    if (ether.toString() == '0.05 ETH') {
      pAmount = 1;
    } else if (ether.toString() == '0.1 ETH') {
      pAmount = 2;
    } else if (ether.toString() == '0.15 ETH') {
      pAmount = 3;
    } else if (ether.toString() == '0.2 ETH') {
      pAmount = 4;
    } else if (ether.toString() == '0.25 ETH') {
      pAmount = 5;
    } else if (ether.toString() == '0.3 ETH') {
      pAmount = 6;
    } else if (ether.toString() == '0.35 ETH') {
      pAmount = 7;
    } else if (ether.toString() == '0.4 ETH') {
      pAmount = 8;
    } else if (ether.toString() == '0.45 ETH') {
      pAmount = 9;
    } else if (ether.toString() == '0.5 ETH') {
      pAmount = 10;
    } else if (ether.toString() == '0.55 ETH') {
      pAmount = 11;
    } else if (ether.toString() == '0.6 ETH') {
      pAmount = 12;
    } else if (ether.toString() == '0.65 ETH') {
      pAmount = 13;
    } else if (ether.toString() == '0.7 ETH') {
      pAmount = 14;
    } else if (ether.toString() == '0.75 ETH') {
      pAmount = 15;
    } else if (ether.toString() == '0.8 ETH') {
      pAmount = 16;
    } else if (ether.toString() == '0.85 ETH') {
      pAmount = 17;
    } else if (ether.toString() == '0.9 ETH') {
      pAmount = 18;
    } else if (ether.toString() == '0.95 ETH') {
      pAmount = 19;
    } else if (ether.toString() == '1 ETH') {
      pAmount = 20;
    } else if (ether.toString() == '1.05 ETH') {
      pAmount = 21;
    } else if (ether.toString() == '1.1 ETH') {
      pAmount = 22;
    }

    if (mintType == 'GIFTNFT') {
      totalAmount = 1;
    } else {
      totalAmount = pAmount;

      if (pAmount == 1) {
        etherAmount = parseInt(50000000000000000).toString(16);
      } else if (pAmount == 2) {
        etherAmount = parseInt(100000000000000000).toString(16);
      } else if (pAmount == 3) {
        etherAmount = parseInt(150000000000000000).toString(16);
      } else if (pAmount == 4) {
        etherAmount = parseInt(200000000000000000).toString(16);
      } else if (pAmount == 5) {
        etherAmount = parseInt(250000000000000000).toString(16);
      } else if (pAmount == 6) {
        etherAmount = parseInt(300000000000000000).toString(16);
      } else if (pAmount == 7) {
        etherAmount = parseInt(350000000000000000).toString(16);
      } else if (pAmount == 8) {
        etherAmount = parseInt(400000000000000000).toString(16);
      } else if (pAmount == 9) {
        etherAmount = parseInt(450000000000000000).toString(16);
      } else if (pAmount == 10) {
        etherAmount = parseInt(500000000000000000).toString(16);
      } else if (pAmount == 11) {
        etherAmount = parseInt(550000000000000000).toString(16);
      } else if (pAmount == 12) {
        etherAmount = parseInt(600000000000000000).toString(16);
      } else if (pAmount == 13) {
        etherAmount = parseInt(650000000000000000).toString(16);
      } else if (pAmount == 14) {
        etherAmount = parseInt(700000000000000000).toString(16);
      } else if (pAmount == 15) {
        etherAmount = parseInt(750000000000000000).toString(16);
      } else if (pAmount == 16) {
        etherAmount = parseInt(800000000000000000).toString(16);
      } else if (pAmount == 17) {
        etherAmount = parseInt(850000000000000000).toString(16);
      } else if (pAmount == 18) {
        etherAmount = parseInt(900000000000000000).toString(16);
      } else if (pAmount == 19) {
        etherAmount = parseInt(950000000000000000).toString(16);
      } else if (pAmount == 20) {
        etherAmount = parseInt(1000000000000000000).toString(16);
      } else if (pAmount == 21) {
        etherAmount = parseInt(1050000000000000000).toString(16);
      } else if (pAmount == 22) {
        etherAmount = parseInt(1100000000000000000).toString(16);
      }
    }

    const giftList = ['0x09b7e5113a681c7a748ac2720d705d38974aa54a', '0xc40638ba9b7928e9b8b796b875c6267d7cf4dc15'];

    const giftMerkleTree = new MerkleTree(giftList, keccak256, {
      hashLeaves: true,
      sortPairs: true,
    });

    //For the contract
    // const giftParticipantRoot = giftMerkleTree.getHexRoot();
    // console.log('giftParticipantRoot for the contract: ', giftParticipantRoot);

    const giftProoof = giftMerkleTree.getHexProof(keccak256(window.ethereum.selectedAddress));

    window.contract = await new web3.eth.Contract(contractABI.abi, contractAddress);

    let transactionParameters;

    if (mintType == 'GIFTNFT') {
      transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        value: etherAmount,
        data: window.contract.methods.mintGiftNFTs(window.ethereum.selectedAddress, giftProoof).encodeABI(),
      };
    } else {
      transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        value: etherAmount,
        data: window.contract.methods.mintNFTs(totalAmount).encodeABI(),
      };
    }

    try {
      const requestResult = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      });

      // console.log('Mint transaction is successful');

      //save address to users
      if (mintType == 'GIFTNFT') {
        await axios.post('/api/saveUser', {
          wallet_address: window.ethereum.selectedAddress,
        });
      }

      return {
        success: true,
      };
    } catch (error) {
      console.log('Mint transaction has failed with issue: ', error);

      return {
        success: false,
        alertMessage: '😥 Something went wrong: ' + error.message,
      };
    }
  }
};

const initiateColorMap = async () => {
  let black = [
    262, 291, 377, 557, 598, 669, 724, 819, 865, 949, 1051, 1112, 1383, 1406, 1408, 1450, 1549, 1568, 1615, 1823, 1834,
    1848, 1905, 2028, 2053, 2163, 2176, 2234, 2612, 2839, 2842, 2950, 3087, 3123, 3188, 3251, 3280, 3388, 3438, 3512,
    3550, 3576, 3607, 3653, 3691, 3771, 3815, 3824, 3843, 3873, 4003, 4026, 4249, 4283, 4334, 4385, 4441, 4625, 4841,
    4874, 4890, 4892, 4938, 4958, 5036, 5120, 5180, 5225, 5549, 5801, 5881, 6127, 6245, 6465, 6483, 6526, 6604, 6692,
    6714, 6734, 6814, 6925, 7092, 7167, 7223, 7339, 7407, 7418, 7735, 7762, 7848, 7882, 7917, 7952, 7978, 8045, 8094,
    8222, 8224, 8353, 8367, 8403, 8570, 8787, 8819, 8882, 8971, 9272, 9322, 9401, 88, 204,
  ];

  for (const element of black) {
    colorMap.set(Number(element), 'BLACK');
  }

  let bluberryblue = [
    28, 34, 37, 40, 68, 70, 72, 83, 92, 94, 104, 116, 149, 161, 200, 222, 323, 343, 376, 405, 475, 515, 567, 588, 601,
    649, 653, 680, 697, 736, 764, 775, 792, 802, 839, 846, 876, 879, 919, 960, 974, 1025, 1030, 1066, 1075, 1095, 1118,
    1130, 1148, 1156, 1215, 1235, 1239, 1247, 1314, 1325, 1370, 1381, 1409, 1417, 1446, 1452, 1459, 1483, 1528, 1535,
    1577, 1583, 1593, 1635, 1646, 1696, 1718, 1797, 1814, 1833, 1845, 1852, 1861, 1862, 1876, 1901, 1915, 1958, 2031,
    2089, 2116, 2155, 2167, 2186, 2224, 2247, 2271, 2284, 2286, 2300, 2302, 2316, 2338, 2381, 2384, 2424, 2450, 2459,
    2476, 2491, 2509, 2517, 2529, 2541, 2562, 2626, 2630, 2648, 2659, 2661, 2670, 2679, 2730, 2758, 2784, 2821, 2832,
    2851, 2863, 2888, 2903, 2905, 2946, 2948, 2949, 2957, 2960, 2966, 3056, 3088, 3100, 3127, 3135, 3145, 3155, 3177,
    3197, 3219, 3246, 3263, 3272, 3300, 3328, 3391, 3396, 3445, 3458, 3526, 3551, 3584, 3596, 3620, 3643, 3646, 3659,
    3684, 3711, 3770, 3777, 3779, 3782, 3796, 3839, 3859, 3908, 3923, 3925, 3953, 3968, 3995, 3996, 4004, 4035, 4041,
    4051, 4103, 4121, 4139, 4149, 4155, 4183, 4268, 4288, 4352, 4355, 4361, 4389, 4398, 4477, 4513, 4529, 4530, 4540,
    4565, 4641, 4648, 4668, 4702, 4706, 4707, 4717, 4738, 4751, 4775, 4814, 4830, 4863, 4926, 4936, 4953, 4979, 5025,
    5044, 5058, 5062, 5074, 5090, 5150, 5201, 5235, 5347, 5356, 5362, 5384, 5395, 5399, 5408, 5490, 5502, 5508, 5516,
    5518, 5522, 5523, 5543, 5593, 5646, 5658, 5719, 5748, 5764, 5787, 5791, 5807, 5818, 5831, 5878, 5879, 5897, 5919,
    5990, 5997, 6012, 6026, 6029, 6044, 6147, 6162, 6166, 6172, 6186, 6204, 6234, 6294, 6337, 6362, 6381, 6397, 6399,
    6418, 6499, 6520, 6524, 6543, 6600, 6636, 6646, 6659, 6663, 6708, 6716, 6746, 6779, 6812, 6825, 6864, 6897, 6922,
    6924, 7026, 7066, 7080, 7133, 7136, 7175, 7176, 7178, 7184, 7312, 7320, 7334, 7338, 7360, 7361, 7385, 7399, 7415,
    7425, 7454, 7459, 7467, 7501, 7551, 7643, 7675, 7700, 7705, 7713, 7748, 7765, 7785, 7842, 7854, 7855, 7880, 7893,
    7958, 8044, 8109, 8133, 8140, 8141, 8183, 8218, 8240, 8258, 8261, 8274, 8280, 8302, 8318, 8328, 8348, 8393, 8429,
    8430, 8432, 8438, 8452, 8460, 8472, 8490, 8504, 8513, 8547, 8567, 8601, 8641, 8647, 8663, 8670, 8718, 8735, 8829,
    8931, 8936, 8978, 8986, 8991, 8998, 9010, 9018, 9036, 9069, 9075, 9107, 9117, 9138, 9162, 9165, 9198, 9237, 9240,
    9259, 9334, 9340, 9342, 9347, 9389, 9406, 9459, 9486, 9488, 9498, 9505, 9506, 9511, 9524, 9530, 9568, 19,
  ];

  for (const element of bluberryblue) {
    colorMap.set(Number(element), 'BLUEBERRY_BLUE');
  }

  let brassFiles = [
    99, 125, 126, 158, 174, 186, 192, 211, 251, 253, 359, 421, 470, 473, 484, 486, 514, 603, 679, 699, 713, 752, 780,
    783, 815, 867, 926, 930, 931, 941, 965, 973, 995, 1043, 1050, 1150, 1167, 1182, 1225, 1244, 1302, 1309, 1351, 1426,
    1508, 1509, 1616, 1684, 1707, 1867, 1875, 1924, 1941, 1954, 1979, 2030, 2042, 2047, 2060, 2091, 2121, 2143, 2188,
    2219, 2237, 2239, 2257, 2258, 2312, 2334, 2342, 2354, 2370, 2441, 2457, 2465, 2504, 2512, 2546, 2589, 2656, 2834,
    2882, 2899, 2906, 2909, 2936, 2982, 3005, 3020, 3023, 3053, 3066, 3071, 3078, 3081, 3157, 3242, 3258, 3285, 3316,
    3319, 3329, 3392, 3469, 3474, 3478, 3524, 3543, 3600, 3615, 3721, 3785, 3803, 3805, 3860, 3882, 3909, 3939, 3981,
    3993, 4002, 4013, 4032, 4124, 4126, 4173, 4182, 4238, 4252, 4255, 4257, 4271, 4282, 4291, 4295, 4324, 4388, 4433,
    4436, 4468, 4474, 4485, 4492, 4502, 4575, 4576, 4579, 4660, 4688, 4787, 4864, 4868, 4897, 4899, 4916, 4918, 4937,
    4970, 5026, 5103, 5105, 5113, 5185, 5186, 5199, 5239, 5266, 5280, 5284, 5337, 5339, 5348, 5410, 5418, 5459, 5460,
    5474, 5528, 5542, 5588, 5684, 5693, 5708, 5769, 5772, 5785, 5794, 5865, 5896, 5957, 5983, 5994, 5996, 6025, 6090,
    6112, 6115, 6170, 6208, 6244, 6270, 6345, 6450, 6458, 6527, 6528, 6553, 6577, 6648, 6686, 6781, 6800, 6828, 6899,
    6950, 6987, 7052, 7089, 7128, 7183, 7198, 7243, 7269, 7359, 7370, 7412, 7428, 7429, 7436, 7439, 7481, 7539, 7550,
    7560, 7574, 7658, 7677, 7691, 7798, 7817, 7863, 7864, 7871, 7898, 7909, 7919, 7962, 7989, 8003, 8032, 8103, 8108,
    8149, 8152, 8202, 8230, 8241, 8311, 8326, 8329, 8428, 8491, 8514, 8545, 8565, 8617, 8635, 8652, 8669, 8698, 8707,
    8729, 8734, 8764, 8767, 8786, 8788, 8800, 8840, 8865, 8869, 8897, 8930, 8947, 8966, 9034, 9058, 9072, 9087, 9128,
    9141, 9161, 9245, 9269, 9309, 9315, 9319, 9331, 9343, 9403, 9430, 9432, 9467, 9495, 9536, 9553, 31,
  ];

  for (const element of brassFiles) {
    colorMap.set(Number(element), 'BRASS');
  }

  let BUBBLEGUM_PINK = [
    49, 69, 74, 100, 105, 112, 129, 134, 135, 139, 150, 155, 177, 179, 202, 203, 216, 229, 235, 236, 241, 270, 278, 301,
    311, 315, 346, 353, 354, 369, 370, 378, 379, 382, 394, 397, 420, 436, 442, 450, 459, 472, 477, 490, 504, 511, 516,
    530, 533, 537, 544, 550, 609, 611, 638, 651, 690, 718, 734, 741, 747, 748, 754, 761, 770, 778, 790, 796, 822, 823,
    835, 842, 849, 851, 856, 864, 881, 901, 918, 936, 942, 971, 975, 979, 1002, 1012, 1023, 1033, 1040, 1079, 1124,
    1145, 1146, 1162, 1169, 1177, 1220, 1223, 1224, 1269, 1291, 1301, 1308, 1326, 1333, 1345, 1369, 1380, 1384, 1407,
    1425, 1434, 1453, 1462, 1466, 1488, 1500, 1501, 1520, 1525, 1537, 1553, 1573, 1576, 1597, 1617, 1632, 1647, 1670,
    1681, 1688, 1699, 1712, 1716, 1733, 1735, 1769, 1781, 1793, 1811, 1827, 1850, 1856, 1871, 1877, 1878, 1885, 1890,
    1896, 1918, 1925, 1930, 1937, 1949, 1965, 1974, 1976, 1977, 1982, 1992, 2004, 2037, 2048, 2066, 2069, 2070, 2075,
    2076, 2081, 2085, 2092, 2110, 2128, 2129, 2147, 2160, 2173, 2202, 2208, 2223, 2245, 2255, 2273, 2304, 2309, 2339,
    2348, 2351, 2353, 2359, 2361, 2363, 2377, 2382, 2391, 2406, 2407, 2414, 2435, 2442, 2464, 2469, 2481, 2489, 2516,
    2538, 2540, 2545, 2559, 2565, 2576, 2597, 2607, 2614, 2651, 2652, 2680, 2682, 2683, 2690, 2697, 2703, 2709, 2714,
    2726, 2734, 2748, 2750, 2759, 2814, 2817, 2833, 2838, 2847, 2848, 2878, 2885, 2900, 2912, 2922, 2931, 2939, 2944,
    3011, 3067, 3091, 3094, 3098, 3111, 3142, 3148, 3164, 3170, 3172, 3231, 3237, 3253, 3271, 3314, 3361, 3394, 3418,
    3424, 3426, 3428, 3432, 3435, 3444, 3455, 3461, 3470, 3472, 3476, 3479, 3484, 3485, 3500, 3503, 3520, 3521, 3546,
    3564, 3573, 3597, 3605, 3610, 3614, 3644, 3651, 3669, 3670, 3673, 3674, 3675, 3686, 3696, 3713, 3736, 3748, 3752,
    3766, 3788, 3829, 3837, 3841, 3852, 3896, 3899, 3913, 3919, 3921, 3941, 3948, 3952, 3956, 3960, 3978, 4015, 4044,
    4059, 4074, 4077, 4083, 4113, 4131, 4204, 4237, 4239, 4258, 4269, 4274, 4275, 4293, 4314, 4335, 4366, 4374, 4377,
    4379, 4400, 4412, 4419, 4423, 4427, 4442, 4446, 4453, 4469, 4479, 4486, 4488, 4489, 4505, 4516, 4518, 4525, 4560,
    4561, 4572, 4586, 4588, 4590, 4604, 4605, 4614, 4632, 4639, 4664, 4672, 4709, 4712, 4725, 4728, 4731, 4737, 4765,
    4768, 4783, 4811, 4832, 4855, 4869, 4875, 4888, 4896, 4900, 4910, 4930, 4934, 4951, 4960, 4963, 4976, 4983, 4986,
    4993, 5002, 5009, 5019, 5032, 5047, 5049, 5089, 5092, 5128, 5132, 5147, 5188, 5222, 5234, 5250, 5259, 5285, 5289,
    5299, 5300, 5317, 5325, 5328, 5353, 5407, 5434, 5437, 5447, 5451, 5529, 5577, 5581, 5596, 5622, 5631, 5640, 5663,
    5681, 5689, 5733, 5740, 5742, 5749, 5773, 5784, 5813, 5841, 5849, 5904, 5913, 5914, 5936, 5941, 5950, 6005, 6063,
    6094, 6116, 6117, 6119, 6174, 6207, 6257, 6263, 6272, 6274, 6276, 6286, 6305, 6308, 6311, 6332, 6365, 6415, 6417,
    6422, 6430, 6433, 6482, 6513, 6516, 6556, 6560, 6561, 6581, 6596, 6608, 6638, 6649, 6658, 6671, 6687, 6688, 6696,
    6709, 6712, 6719, 6720, 6759, 6783, 6798, 6803, 6817, 6830, 6836, 6852, 6857, 6871, 6879, 6890, 6903, 6937, 6944,
    6946, 6948, 6949, 6953, 6954, 6958, 6981, 6990, 7015, 7016, 7023, 7077, 7079, 7105, 7108, 7117, 7120, 7124, 7145,
    7149, 7190, 7193, 7211, 7213, 7242, 7246, 7249, 7250, 7268, 7290, 7337, 7365, 7372, 7386, 7396, 7424, 7487, 7517,
    7532, 7536, 7554, 7579, 7593, 7600, 7603, 7616, 7619, 7632, 7670, 7687, 7706, 7760, 7774, 7780, 7803, 7811, 7820,
    7830, 7835, 7843, 7850, 7869, 7881, 7888, 7918, 7923, 7928, 7933, 7948, 7968, 7977, 7983, 7996, 7999, 8010, 8017,
    8022, 8057, 8067, 8088, 8121, 8136, 8137, 8146, 8153, 8155, 8171, 8242, 8249, 8263, 8270, 8299, 8313, 8368, 8385,
    8392, 8399, 8413, 8422, 8436, 8449, 8451, 8453, 8470, 8507, 8522, 8535, 8557, 8559, 8560, 8562, 8578, 8588, 8600,
    8607, 8623, 8642, 8677, 8681, 8686, 8699, 8700, 8703, 8708, 8722, 8743, 8777, 8780, 8791, 8836, 8837, 8854, 8855,
    8859, 8875, 8887, 8889, 8909, 8922, 8935, 8938, 8940, 8948, 8959, 8968, 9004, 9049, 9071, 9089, 9140, 9142, 9164,
    9179, 9208, 9214, 9219, 9241, 9244, 9254, 9271, 9293, 9299, 9305, 9321, 9344, 9348, 9350, 9367, 9372, 9376, 9412,
    9445, 9470, 9480, 9492, 9500, 9571, 9593, 9598, 10,
  ];

  for (const element of BUBBLEGUM_PINK) {
    colorMap.set(Number(element), 'BUBBLEGUM_PINK');
  }

  let CANDY_BLUSH_PINK = [
    113, 266, 272, 282, 393, 446, 570, 640, 662, 738, 769, 850, 915, 924, 925, 943, 983, 1005, 1020, 1038, 1046, 1061,
    1070, 1085, 1131, 1144, 1158, 1192, 1196, 1267, 1329, 1460, 1495, 1540, 1557, 1574, 1595, 1598, 1603, 1612, 1653,
    1689, 1723, 2016, 2126, 2135, 2140, 2164, 2266, 2337, 2394, 2423, 2439, 2490, 2570, 2599, 2628, 2684, 2706, 2738,
    2870, 2871, 2928, 2973, 2992, 2995, 3131, 3137, 3158, 3198, 3229, 3284, 3311, 3337, 3341, 3347, 3381, 3427, 3549,
    3553, 3692, 3712, 3717, 3980, 4033, 4040, 4045, 4066, 4091, 4140, 4200, 4203, 4223, 4382, 4490, 4527, 4567, 4587,
    4743, 4752, 4771, 4772, 4796, 4803, 4810, 4817, 4834, 4843, 4857, 4861, 4967, 4999, 5060, 5065, 5087, 5121, 5136,
    5144, 5159, 5195, 5271, 5294, 5296, 5343, 5390, 5392, 5442, 5484, 5486, 5499, 5630, 5692, 5726, 5731, 5766, 5817,
    5828, 5859, 5986, 6043, 6049, 6091, 6155, 6180, 6196, 6302, 6343, 6347, 6351, 6574, 6576, 6580, 6584, 6606, 6670,
    6673, 6750, 6766, 6875, 6975, 6986, 7049, 7063, 7064, 7067, 7112, 7284, 7295, 7349, 7379, 7390, 7401, 7567, 7630,
    7707, 7717, 7746, 7907, 7910, 7995, 8143, 8198, 8208, 8232, 8244, 8257, 8272, 8291, 8317, 8354, 8532, 8538, 8591,
    8630, 8654, 8784, 8806, 8827, 8958, 9042, 9070, 9090, 9105, 9110, 9229, 9295, 9346, 9362, 9377, 9378, 9414, 9416,
    9479, 9580, 5,
  ];

  for (const element of CANDY_BLUSH_PINK) {
    colorMap.set(Number(element), 'CANDY_BLUSH_PINK');
  }

  let CHROME = [
    95, 193, 257, 298, 386, 508, 628, 639, 655, 709, 1018, 1087, 1088, 1149, 1191, 1211, 1303, 1307, 1349, 1440, 1887,
    1942, 1985, 2025, 2084, 2119, 2268, 2427, 2513, 2642, 2649, 2669, 2685, 2741, 2786, 2825, 2892, 2916, 3159, 3181,
    3236, 3401, 3565, 3666, 3697, 3797, 3809, 3823, 3825, 3847, 3890, 3910, 4049, 4109, 4117, 4145, 4197, 4199, 4235,
    4270, 4303, 4331, 4770, 4790, 5046, 5153, 5155, 5220, 5423, 5520, 5570, 5711, 5714, 5774, 5932, 6059, 6067, 6145,
    6197, 6249, 6268, 6457, 6542, 6795, 6831, 6926, 7241, 7362, 7510, 7556, 7613, 7769, 7936, 8106, 8170, 8347, 8375,
    8407, 8580, 8593, 8820, 8868, 8872, 9005, 9096, 9169, 9189, 9250, 9352, 9469, 9484, 9526, 9542, 3,
  ];

  for (const element of CHROME) {
    colorMap.set(Number(element), 'CHROME');
  }

  let CLEAR = [
    654, 723, 863, 1198, 1738, 1763, 1838, 2737, 3685, 3808, 4723, 5857, 8001, 8197, 8199, 8483, 8606, 9158, 9194, 287,
  ];

  for (const element of CLEAR) {
    colorMap.set(Number(element), 'CLEAR');
  }

  let EMERALD_GREEN = [
    7194, 7204, 7217, 7218, 7239, 7240, 7265, 7272, 7277, 7307, 7309, 7321, 7323, 7327, 7350, 7356, 7364, 7366, 7373,
    7377, 7381, 7392, 7423, 7448, 7462, 7464, 7465, 7504, 7508, 7533, 7534, 7538, 7549, 7558, 7580, 7583, 7585, 7599,
    7602, 7607, 7608, 7617, 7667, 7668, 7671, 7680, 7688, 7692, 7694, 7695, 7696, 7708, 7719, 7729, 7737, 7738, 7747,
    7752, 7753, 7778, 7783, 7787, 7789, 7795, 7799, 7802, 7806, 7808, 7809, 7814, 7831, 7832, 7837, 7841, 7845, 7853,
    7872, 7875, 7914, 7921, 7925, 7932, 7937, 7956, 7957, 7970, 7971, 8008, 8011, 8013, 8018, 8019, 8020, 8034, 8038,
    8060, 8081, 8084, 8096, 8100, 8102, 8119, 8144, 8154, 8158, 8179, 8195, 8212, 8214, 8252, 8279, 8306, 8307, 8316,
    8330, 8331, 8334, 8344, 8380, 8389, 8404, 8424, 8485, 8493, 8503, 8517, 8525, 8530, 8533, 8546, 8549, 8572, 8602,
    8609, 8610, 8612, 8613, 8631, 8643, 8650, 8661, 8676, 8679, 8688, 8689, 8694, 8709, 8716, 8727, 8730, 8765, 8773,
    8789, 8802, 8818, 8824, 8831, 8832, 8834, 8846, 8847, 8848, 8861, 8874, 8885, 8886, 8891, 8892, 8902, 8917, 8933,
    8976, 9002, 9020, 9027, 9051, 9068, 9076, 9079, 9101, 9106, 9121, 9125, 9130, 9137, 9171, 9191, 9203, 9207, 9233,
    9234, 9261, 9265, 9279, 9283, 9285, 9313, 9318, 9380, 9388, 9392, 9402, 9428, 9452, 9464, 9465, 9468, 9475, 9501,
    9507, 9509, 9525, 9541, 9544, 9561, 9563, 9570, 38, 53, 57, 61, 77, 87, 89, 106, 132, 133, 157, 163, 176, 187, 190,
    196, 199, 207, 210, 212, 217, 233, 269, 281, 284, 285, 286, 290, 313, 327, 349, 357, 366, 390, 438, 462, 463, 479,
    481, 482, 491, 501, 512, 517, 519, 536, 539, 551, 555, 556, 558, 561, 580, 583, 591, 623, 626, 644, 648, 670, 684,
    692, 700, 712, 715, 732, 744, 753, 755, 784, 787, 830, 837, 844, 853, 870, 878, 906, 912, 950, 953, 957, 958, 977,
    994, 1003, 1008, 1024, 1034, 1036, 1037, 1062, 1072, 1083, 1106, 1125, 1137, 1138, 1168, 1179, 1185, 1197, 1201,
    1213, 1222, 1227, 1231, 1234, 1240, 1265, 1277, 1284, 1294, 1321, 1334, 1355, 1356, 1362, 1363, 1366, 1376, 1387,
    1388, 1398, 1419, 1432, 1436, 1455, 1456, 1464, 1484, 1498, 1527, 1536, 1551, 1567, 1621, 1622, 1625, 1629, 1637,
    1660, 1662, 1664, 1668, 1672, 1678, 1698, 1713, 1730, 1750, 1757, 1764, 1765, 1768, 1775, 1782, 1785, 1795, 1804,
    1821, 1826, 1836, 1842, 1849, 1855, 1879, 1895, 1900, 1911, 1945, 1948, 1957, 1960, 1972, 1989, 1996, 2005, 2013,
    2029, 2041, 2056, 2057, 2077, 2115, 2117, 2123, 2134, 2168, 2175, 2194, 2207, 2211, 2212, 2213, 2232, 2250, 2259,
    2269, 2283, 2292, 2298, 2305, 2331, 2332, 2350, 2366, 2367, 2380, 2387, 2410, 2417, 2430, 2449, 2482, 2487, 2508,
    2515, 2519, 2528, 2532, 2542, 2574, 2584, 2586, 2608, 2618, 2621, 2641, 2654, 2664, 2667, 2691, 2693, 2695, 2712,
    2735, 2743, 2745, 2754, 2769, 2782, 2791, 2794, 2796, 2801, 2813, 2829, 2841, 2846, 2890, 2893, 2914, 2924, 2942,
    2947, 2985, 2989, 2998, 3003, 3029, 3042, 3043, 3051, 3059, 3060, 3072, 3104, 3121, 3133, 3139, 3141, 3161, 3179,
    3182, 3191, 3215, 3249, 3250, 3282, 3289, 3294, 3298, 3303, 3312, 3317, 3336, 3342, 3348, 3362, 3373, 3384, 3405,
    3413, 3419, 3421, 3436, 3464, 3468, 3480, 3482, 3492, 3498, 3507, 3509, 3511, 3515, 3519, 3523, 3528, 3533, 3538,
    3548, 3554, 3571, 3582, 3583, 3602, 3621, 3626, 3628, 3647, 3652, 3660, 3663, 3671, 3672, 3676, 3681, 3694, 3703,
    3709, 3733, 3735, 3741, 3745, 3753, 3767, 3781, 3783, 3791, 3804, 3810, 3819, 3820, 3831, 3833, 3874, 3887, 3892,
    3905, 3928, 3977, 3992, 4008, 4009, 4022, 4057, 4067, 4069, 4080, 4111, 4127, 4129, 4132, 4133, 4136, 4138, 4142,
    4171, 4194, 4201, 4202, 4212, 4215, 4253, 4264, 4265, 4294, 4304, 4308, 4310, 4319, 4333, 4341, 4346, 4358, 4362,
    4372, 4387, 4391, 4399, 4402, 4403, 4414, 4417, 4437, 4447, 4457, 4472, 4483, 4501, 4547, 4548, 4551, 4562, 4573,
    4589, 4594, 4601, 4608, 4612, 4613, 4617, 4624, 4635, 4637, 4640, 4655, 4658, 4671, 4681, 4696, 4714, 4722, 4727,
    4733, 4746, 4766, 4780, 4781, 4816, 4821, 4840, 4850, 4856, 4877, 4893, 4909, 4919, 4924, 4925, 4932, 4949, 4955,
    4957, 4961, 4962, 4971, 4973, 4981, 5000, 5001, 5015, 5059, 5063, 5097, 5104, 5117, 5134, 5151, 5167, 5171, 5172,
    5182, 5183, 5189, 5190, 5196, 5212, 5227, 5241, 5249, 5256, 5264, 5270, 5273, 5279, 5281, 5304, 5318, 5327, 5334,
    5336, 5338, 5359, 5393, 5397, 5400, 5403, 5405, 5416, 5432, 5436, 5453, 5471, 5473, 5535, 5556, 5559, 5563, 5566,
    5587, 5589, 5592, 5594, 5595, 5603, 5607, 5613, 5639, 5648, 5653, 5661, 5673, 5675, 5683, 5685, 5700, 5728, 5744,
    5756, 5767, 5789, 5803, 5809, 5812, 5820, 5826, 5850, 5851, 5852, 5853, 5862, 5872, 5888, 5902, 5906, 5909, 5927,
    5929, 5938, 5948, 5954, 5967, 5979, 6002, 6016, 6022, 6028, 6030, 6035, 6058, 6061, 6068, 6071, 6074, 6081, 6083,
    6088, 6100, 6105, 6106, 6124, 6131, 6146, 6149, 6158, 6159, 6190, 6192, 6200, 6214, 6224, 6236, 6238, 6248, 6265,
    6279, 6285, 6303, 6313, 6335, 6338, 6357, 6358, 6361, 6364, 6366, 6378, 6416, 6426, 6427, 6432, 6446, 6452, 6453,
    6454, 6456, 6463, 6479, 6490, 6508, 6509, 6517, 6536, 6539, 6549, 6550, 6554, 6566, 6567, 6583, 6611, 6616, 6620,
    6627, 6645, 6650, 6652, 6666, 6677, 6679, 6685, 6693, 6695, 6703, 6711, 6731, 6796, 6797, 6810, 6823, 6859, 6866,
    6878, 6884, 6893, 6901, 6912, 6913, 6918, 6943, 6951, 6955, 6956, 6962, 6991, 7022, 7032, 7042, 7078, 7085, 7097,
    7103, 7118, 7126, 7130, 7131, 7146, 7153, 7168, 7180, 7181, 7182,
  ];

  for (const element of EMERALD_GREEN) {
    colorMap.set(Number(element), 'EMERALD_GREEN');
  }

  let GOLD = [
    3045, 3062, 3095, 3171, 3190, 3269, 3581, 3619, 3848, 3856, 3974, 4010, 4038, 4118, 4137, 4151, 4158, 4220, 4674,
    4693, 4729, 4735, 4760, 4792, 4800, 4859, 4891, 4922, 4991, 5018, 5045, 5123, 5174, 5276, 5380, 5444, 5519, 5599,
    5690, 5706, 5727, 5793, 5845, 5890, 5940, 6010, 6021, 6137, 6148, 6217, 6287, 6321, 6425, 6477, 6507, 6601, 6672,
    6786, 6832, 7045, 7060, 7276, 7358, 7368, 7529, 7634, 7651, 7652, 7757, 7763, 7847, 8092, 8192, 8204, 8295, 8480,
    8486, 8687, 8781, 8873, 8896, 8999, 9007, 9183, 9202, 9323, 9485, 9534, 9588, 44, 58, 194, 425, 632, 705, 801, 880,
    1233, 1418, 1441, 1591, 1669, 1787, 2019, 2097, 2159, 2216, 2344, 2412, 2418, 2563, 2655, 2660, 2746, 2774,
  ];

  for (const element of GOLD) {
    colorMap.set(Number(element), 'GOLD');
  }

  let GRAPE_PURPLE = [
    336, 342, 348, 355, 398, 399, 431, 439, 461, 485, 500, 510, 522, 538, 568, 605, 631, 664, 681, 682, 687, 696, 730,
    749, 750, 759, 773, 776, 799, 810, 889, 895, 899, 908, 910, 917, 927, 998, 1021, 1035, 1039, 1048, 1054, 1064, 1096,
    1121, 1164, 1176, 1183, 1186, 1203, 1206, 1208, 1212, 1226, 1229, 1279, 1285, 1292, 1297, 1365, 1474, 1496, 1503,
    1514, 1517, 1532, 1544, 1594, 1623, 1627, 1642, 1651, 1656, 1657, 1682, 1690, 1739, 1743, 1772, 1780, 1802, 1805,
    1857, 1858, 1888, 1946, 1959, 1963, 1991, 1994, 2002, 2023, 2024, 2032, 2086, 2093, 2096, 2104, 2111, 2148, 2152,
    2161, 2165, 2170, 2179, 2180, 2183, 2190, 2199, 2220, 2240, 2248, 2278, 2281, 2333, 2343, 2345, 2374, 2383, 2399,
    2458, 2475, 2496, 2501, 2506, 2510, 2518, 2522, 2573, 2583, 2588, 2595, 2603, 2604, 2632, 2643, 2647, 2676, 2788,
    2797, 2843, 2857, 2872, 2930, 2943, 2976, 2988, 2991, 3001, 3014, 3018, 3073, 3107, 3114, 3117, 3156, 3178, 3207,
    3226, 3228, 3232, 3276, 3286, 3295, 3307, 3325, 3359, 3364, 3375, 3382, 3400, 3410, 3447, 3448, 3450, 3453, 3456,
    3467, 3497, 3514, 3525, 3531, 3536, 3541, 3579, 3588, 3604, 3609, 3648, 3668, 3683, 3701, 3740, 3751, 3787, 3799,
    3844, 3851, 3861, 3864, 3875, 3943, 3976, 4042, 4053, 4075, 4078, 4093, 4095, 4096, 4097, 4098, 4122, 4128, 4143,
    4180, 4191, 4218, 4267, 4299, 4306, 4317, 4320, 4326, 4330, 4344, 4360, 4394, 4397, 4405, 4415, 4428, 4430, 4443,
    4450, 4455, 4484, 4491, 4509, 4569, 4611, 4642, 4647, 4652, 4699, 4739, 4758, 4789, 4809, 4827, 4852, 4886, 4906,
    4948, 4968, 5076, 5108, 5138, 5145, 5152, 5178, 5187, 5237, 5248, 5305, 5316, 5344, 5383, 5391, 5396, 5398, 5402,
    5413, 5414, 5428, 5448, 5455, 5458, 5467, 5476, 5515, 5537, 5538, 5545, 5555, 5569, 5611, 5615, 5616, 5624, 5649,
    5677, 5688, 5722, 5723, 5746, 5750, 5754, 5829, 5883, 5910, 5956, 5963, 5966, 5969, 5999, 6008, 6020, 6053, 6054,
    6120, 6128, 6161, 6220, 6243, 6253, 6264, 6324, 6325, 6330, 6349, 6353, 6360, 6389, 6423, 6424, 6434, 6440, 6460,
    6470, 6474, 6496, 6500, 6512, 6545, 6582, 6603, 6609, 6657, 6676, 6702, 6722, 6738, 6741, 6743, 6752, 6753, 6758,
    6775, 6785, 6839, 6851, 6880, 6904, 6930, 6957, 6972, 6983, 7018, 7029, 7038, 7061, 7102, 7141, 7171, 7172, 7187,
    7192, 7210, 7225, 7234, 7238, 7278, 7296, 7324, 7404, 7413, 7443, 7444, 7457, 7470, 7503, 7552, 7557, 7565, 7573,
    7589, 7601, 7610, 7636, 7704, 7721, 7724, 7728, 7730, 7734, 7759, 7784, 7813, 7834, 7849, 7862, 7905, 7906, 7924,
    7930, 7980, 7997, 8002, 8016, 8036, 8051, 8068, 8083, 8086, 8130, 8169, 8174, 8175, 8176, 8180, 8185, 8201, 8217,
    8228, 8239, 8278, 8287, 8355, 8365, 8414, 8435, 8437, 8444, 8463, 8509, 8519, 8534, 8542, 8577, 8586, 8620, 8644,
    8675, 8680, 8697, 8704, 8711, 8717, 8745, 8746, 8749, 8752, 8783, 8799, 8852, 8862, 8863, 8871, 8895, 8898, 8919,
    8923, 8927, 8962, 8973, 8994, 9016, 9025, 9039, 9066, 9147, 9151, 9186, 9270, 9296, 9335, 9360, 9404, 9440, 9443,
    9473, 9528, 9552, 9555, 9565, 9577, 20, 41, 90, 124, 152, 184, 185, 206, 218, 226, 231, 237, 242, 300,
  ];

  for (const element of GRAPE_PURPLE) {
    colorMap.set(Number(element), 'GRAPE_PURPLE');
  }

  let GRASS_GREEN = [
    402, 415, 435, 441, 445, 471, 474, 525, 526, 546, 572, 590, 606, 620, 646, 746, 800, 933, 1069, 1105, 1135, 1154,
    1155, 1190, 1219, 1262, 1305, 1344, 1353, 1428, 1505, 1550, 1555, 1596, 1620, 1634, 1640, 1676, 1683, 1691, 1725,
    1760, 1774, 1777, 1847, 1913, 1914, 1916, 1933, 1947, 1962, 1968, 1980, 2010, 2059, 2098, 2112, 2177, 2293, 2318,
    2443, 2451, 2560, 2591, 2593, 2644, 2663, 2707, 2716, 2721, 2806, 3038, 3102, 3151, 3152, 3187, 3211, 3223, 3268,
    3275, 3310, 3313, 3318, 3334, 3338, 3437, 3460, 3466, 3489, 3517, 3542, 3598, 3718, 3727, 3747, 3760, 3761, 3772,
    3812, 3836, 3868, 3884, 3924, 3954, 3966, 3967, 3975, 4020, 4058, 4061, 4071, 4157, 4159, 4178, 4192, 4222, 4224,
    4242, 4307, 4325, 4339, 4401, 4410, 4445, 4459, 4463, 4541, 4549, 4619, 4623, 4695, 4730, 4740, 4741, 4774, 4835,
    4837, 4904, 5006, 5007, 5029, 5070, 5077, 5101, 5119, 5124, 5193, 5261, 5268, 5282, 5306, 5329, 5340, 5385, 5422,
    5427, 5433, 5510, 5524, 5552, 5568, 5598, 5686, 5691, 5753, 5827, 5836, 5837, 5843, 5861, 5870, 5876, 5943, 5961,
    5995, 6206, 6209, 6242, 6254, 6275, 6292, 6340, 6371, 6374, 6398, 6565, 6572, 6586, 6602, 6730, 6751, 6808, 6848,
    6868, 6872, 6908, 6920, 6934, 6989, 7069, 7090, 7099, 7113, 7199, 7200, 7332, 7351, 7405, 7463, 7475, 7499, 7505,
    7506, 7545, 7553, 7559, 7659, 7714, 7741, 7749, 7766, 7792, 7797, 7818, 7824, 7870, 7878, 7890, 7965, 7973, 7988,
    8005, 8024, 8029, 8069, 8087, 8157, 8238, 8255, 8264, 8285, 8320, 8417, 8431, 8441, 8499, 8568, 8594, 8685, 8766,
    8768, 8794, 8812, 8815, 8817, 8822, 8876, 8888, 8939, 8952, 8961, 8964, 8974, 8993, 9008, 9033, 9080, 9085, 9086,
    9102, 9104, 9111, 9167, 9224, 9247, 9253, 9287, 9302, 9338, 9359, 9373, 9415, 9497, 9545, 9592, 30, 108, 110, 153,
    167, 180, 219, 275, 293, 297, 324, 345, 362, 389,
  ];

  for (const element of GRASS_GREEN) {
    colorMap.set(Number(element), 'GRASS_GREEN');
  }

  let HOT_SAUCE_RED = [
    381, 404, 411, 423, 449, 468, 483, 523, 541, 560, 618, 625, 643, 667, 683, 708, 726, 804, 806, 845, 873, 884, 894,
    900, 904, 907, 1092, 1093, 1103, 1114, 1141, 1142, 1165, 1188, 1209, 1232, 1276, 1289, 1310, 1317, 1319, 1372, 1382,
    1389, 1411, 1437, 1457, 1458, 1538, 1554, 1560, 1592, 1677, 1709, 1720, 1729, 1755, 1766, 1771, 1806, 1817, 1819,
    1835, 1839, 1841, 1843, 1844, 1903, 1917, 1944, 1966, 1999, 2020, 2022, 2033, 2064, 2080, 2114, 2124, 2132, 2171,
    2198, 2210, 2217, 2225, 2238, 2244, 2262, 2265, 2267, 2325, 2355, 2357, 2360, 2395, 2396, 2437, 2440, 2454, 2471,
    2477, 2495, 2503, 2521, 2543, 2590, 2609, 2653, 2665, 2672, 2677, 2696, 2701, 2733, 2771, 2790, 2800, 2808, 2812,
    2827, 2855, 2868, 2875, 2907, 2911, 2923, 2933, 2945, 2967, 3024, 3031, 3049, 3058, 3061, 3093, 3103, 3167, 3193,
    3196, 3199, 3200, 3240, 3243, 3273, 3274, 3291, 3305, 3322, 3344, 3360, 3369, 3434, 3493, 3516, 3530, 3535, 3552,
    3556, 3566, 3616, 3625, 3645, 3655, 3661, 3699, 3706, 3725, 3738, 3750, 3775, 3862, 3881, 3889, 3922, 3931, 3957,
    4028, 4034, 4063, 4076, 4088, 4101, 4116, 4135, 4153, 4160, 4164, 4169, 4184, 4193, 4244, 4259, 4261, 4276, 4277,
    4285, 4290, 4434, 4449, 4451, 4458, 4460, 4478, 4482, 4507, 4517, 4536, 4580, 4596, 4616, 4634, 4663, 4682, 4689,
    4753, 4784, 4807, 4828, 4836, 4853, 4860, 4939, 4974, 4988, 4992, 4995, 5016, 5028, 5037, 5038, 5043, 5081, 5088,
    5093, 5116, 5126, 5161, 5165, 5177, 5200, 5203, 5216, 5233, 5252, 5258, 5275, 5298, 5315, 5469, 5480, 5505, 5511,
    5513, 5536, 5548, 5551, 5560, 5565, 5576, 5608, 5628, 5645, 5654, 5662, 5664, 5671, 5678, 5694, 5755, 5759, 5786,
    5795, 5823, 5832, 5833, 5838, 5864, 5871, 5884, 5894, 5926, 5939, 5942, 5949, 5975, 5978, 5982, 5991, 6003, 6014,
    6056, 6064, 6069, 6070, 6085, 6086, 6098, 6134, 6212, 6218, 6222, 6227, 6281, 6323, 6329, 6341, 6363, 6385, 6387,
    6392, 6414, 6449, 6459, 6468, 6476, 6503, 6548, 6587, 6614, 6615, 6618, 6628, 6662, 6683, 6700, 6726, 6728, 6735,
    6739, 6748, 6763, 6782, 6790, 6802, 6813, 6870, 6873, 6886, 6892, 6902, 6906, 6914, 6964, 6976, 6977, 6982, 6994,
    6995, 7013, 7017, 7033, 7068, 7082, 7094, 7123, 7164, 7205, 7256, 7257, 7260, 7273, 7282, 7286, 7308, 7313, 7317,
    7403, 7434, 7446, 7455, 7460, 7483, 7486, 7516, 7528, 7530, 7570, 7572, 7609, 7612, 7640, 7660, 7674, 7676, 7699,
    7750, 7801, 7805, 7819, 7825, 7857, 7858, 7868, 7884, 7902, 7912, 7951, 7959, 8000, 8014, 8031, 8033, 8042, 8059,
    8073, 8076, 8112, 8114, 8125, 8163, 8172, 8184, 8186, 8189, 8205, 8211, 8227, 8229, 8260, 8301, 8315, 8333, 8335,
    8338, 8351, 8361, 8402, 8410, 8418, 8420, 8455, 8481, 8484, 8501, 8614, 8622, 8673, 8710, 8714, 8738, 8755, 8774,
    8775, 8785, 8813, 8844, 8853, 8881, 8890, 8901, 8905, 8944, 8969, 8975, 8980, 8982, 9035, 9115, 9122, 9132, 9196,
    9200, 9213, 9223, 9225, 9227, 9238, 9242, 9291, 9292, 9325, 9332, 9333, 9353, 9381, 9382, 9409, 9427, 9436, 9456,
    9476, 9482, 9535, 9569, 9575, 21, 36, 109, 117, 128, 137, 141, 170, 268, 310, 314, 331, 341, 360,
  ];

  for (const element of HOT_SAUCE_RED) {
    colorMap.set(Number(element), 'HOT_SAUCE_RED');
  }

  let LAVENDER_PURPLE = [
    45, 75, 107, 111, 115, 148, 173, 178, 223, 225, 240, 247, 248, 305, 325, 364, 417, 429, 498, 527, 559, 562, 621,
    627, 676, 689, 701, 721, 728, 739, 794, 807, 827, 833, 868, 944, 956, 964, 990, 991, 1044, 1047, 1058, 1111, 1152,
    1157, 1250, 1255, 1320, 1324, 1343, 1393, 1403, 1422, 1423, 1477, 1485, 1529, 1530, 1563, 1566, 1604, 1641, 1652,
    1685, 1692, 1702, 1710, 1717, 1722, 1728, 1747, 1759, 1767, 1776, 1796, 1808, 1809, 1868, 1893, 1906, 1920, 1938,
    1983, 1990, 1997, 2045, 2046, 2063, 2125, 2144, 2149, 2150, 2243, 2261, 2276, 2282, 2287, 2301, 2310, 2313, 2319,
    2324, 2365, 2373, 2375, 2385, 2388, 2411, 2452, 2461, 2549, 2552, 2558, 2564, 2577, 2624, 2638, 2639, 2650, 2694,
    2740, 2762, 2798, 2810, 2861, 2887, 2926, 2969, 2977, 3006, 3026, 3083, 3092, 3109, 3122, 3184, 3203, 3216, 3217,
    3259, 3265, 3267, 3324, 3332, 3335, 3350, 3389, 3414, 3496, 3499, 3505, 3561, 3574, 3590, 3591, 3627, 3629, 3658,
    3690, 3693, 3732, 3762, 3763, 3768, 3773, 3776, 3790, 3834, 3842, 3850, 3858, 3886, 3914, 3945, 3986, 4018, 4052,
    4056, 4068, 4119, 4130, 4162, 4187, 4206, 4219, 4240, 4241, 4248, 4305, 4309, 4315, 4323, 4328, 4351, 4390, 4411,
    4426, 4466, 4476, 4558, 4607, 4620, 4657, 4667, 4684, 4694, 4711, 4754, 4767, 4812, 4839, 4851, 4921, 4996, 5017,
    5022, 5041, 5054, 5078, 5079, 5098, 5118, 5127, 5154, 5218, 5288, 5309, 5313, 5377, 5417, 5440, 5478, 5479, 5541,
    5571, 5582, 5591, 5612, 5643, 5652, 5715, 5729, 5732, 5736, 5776, 5802, 5815, 5824, 5835, 5892, 5900, 5933, 5934,
    5937, 5962, 6038, 6077, 6101, 6133, 6140, 6153, 6182, 6184, 6239, 6419, 6435, 6438, 6493, 6495, 6515, 6530, 6585,
    6621, 6640, 6643, 6694, 6713, 6737, 6757, 6773, 6801, 6834, 6858, 6865, 6931, 6939, 6959, 6960, 6965, 7020, 7055,
    7056, 7114, 7122, 7212, 7214, 7245, 7261, 7267, 7280, 7310, 7314, 7315, 7330, 7340, 7376, 7474, 7484, 7597, 7605,
    7624, 7628, 7629, 7638, 7655, 7712, 7725, 7791, 7807, 7822, 7829, 7844, 7856, 7876, 7892, 7929, 7941, 7985, 7994,
    8052, 8063, 8085, 8236, 8304, 8308, 8321, 8323, 8341, 8364, 8370, 8395, 8401, 8419, 8434, 8446, 8458, 8465, 8466,
    8539, 8571, 8585, 8649, 8653, 8658, 8664, 8741, 8744, 8748, 8750, 8753, 8758, 8796, 8804, 8809, 8816, 8823, 8856,
    8924, 8951, 8984, 8990, 9011, 9022, 9043, 9047, 9062, 9067, 9116, 9136, 9146, 9221, 9231, 9236, 9248, 9274, 9278,
    9284, 9328, 9351, 9370, 9399, 9434, 9439, 9446, 9455, 9533, 9573, 9579, 9583, 9591, 7, 25,
  ];

  for (const element of LAVENDER_PURPLE) {
    colorMap.set(Number(element), 'LAVENDER_PURPLE');
  }

  let LEMON_YELLOW = [
    220, 238, 239, 250, 252, 289, 292, 302, 309, 321, 347, 387, 407, 428, 451, 453, 476, 494, 499, 518, 524, 528, 532,
    549, 566, 575, 581, 587, 595, 615, 617, 624, 633, 634, 635, 637, 641, 663, 672, 685, 719, 720, 722, 737, 743, 771,
    779, 786, 818, 826, 847, 858, 875, 898, 903, 911, 954, 955, 969, 988, 996, 1004, 1006, 1010, 1017, 1022, 1028, 1029,
    1041, 1052, 1077, 1089, 1108, 1113, 1117, 1132, 1134, 1139, 1173, 1184, 1189, 1194, 1204, 1205, 1217, 1218, 1221,
    1238, 1245, 1257, 1259, 1266, 1272, 1278, 1288, 1298, 1327, 1359, 1364, 1377, 1391, 1392, 1405, 1410, 1413, 1421,
    1439, 1443, 1451, 1468, 1470, 1478, 1479, 1481, 1482, 1489, 1497, 1531, 1547, 1556, 1562, 1575, 1578, 1581, 1601,
    1606, 1607, 1613, 1667, 1679, 1680, 1701, 1714, 1737, 1753, 1758, 1770, 1791, 1800, 1822, 1840, 1863, 1891, 1894,
    1908, 1909, 1921, 1943, 1975, 1984, 1993, 2012, 2043, 2058, 2118, 2138, 2139, 2151, 2182, 2192, 2197, 2200, 2201,
    2205, 2227, 2228, 2229, 2242, 2260, 2270, 2290, 2291, 2303, 2317, 2322, 2352, 2368, 2378, 2379, 2398, 2401, 2404,
    2405, 2409, 2420, 2426, 2431, 2433, 2434, 2446, 2462, 2472, 2478, 2492, 2494, 2548, 2550, 2554, 2572, 2580, 2601,
    2610, 2613, 2625, 2636, 2645, 2674, 2681, 2686, 2689, 2692, 2698, 2702, 2705, 2711, 2713, 2715, 2725, 2736, 2739,
    2747, 2753, 2815, 2819, 2864, 2883, 2898, 2910, 2920, 2927, 2968, 2979, 2986, 2994, 2997, 2999, 3002, 3004, 3017,
    3027, 3039, 3065, 3068, 3082, 3099, 3105, 3108, 3115, 3119, 3126, 3128, 3160, 3169, 3180, 3225, 3234, 3256, 3262,
    3297, 3309, 3339, 3368, 3370, 3371, 3372, 3399, 3402, 3422, 3459, 3465, 3471, 3483, 3490, 3494, 3502, 3504, 3506,
    3572, 3593, 3594, 3606, 3634, 3638, 3695, 3698, 3714, 3716, 3724, 3731, 3759, 3789, 3814, 3818, 3821, 3822, 3828,
    3845, 3857, 3863, 3866, 3870, 3907, 3911, 3926, 3934, 3935, 3951, 3969, 3985, 4006, 4011, 4017, 4029, 4039, 4043,
    4047, 4055, 4064, 4065, 4082, 4105, 4108, 4115, 4208, 4209, 4251, 4279, 4280, 4281, 4300, 4302, 4327, 4364, 4370,
    4384, 4392, 4395, 4406, 4416, 4420, 4424, 4471, 4500, 4506, 4511, 4521, 4532, 4584, 4593, 4595, 4606, 4626, 4627,
    4638, 4644, 4656, 4670, 4677, 4678, 4692, 4732, 4736, 4747, 4778, 4782, 4786, 4794, 4795, 4797, 4801, 4802, 4823,
    4848, 4872, 4873, 4880, 4882, 4895, 4902, 4935, 4940, 4944, 4946, 4954, 4975, 4978, 4998, 5014, 5027, 5034, 5051,
    5055, 5061, 5075, 5084, 5095, 5099, 5109, 5142, 5181, 5191, 5198, 5205, 5207, 5211, 5230, 5262, 5263, 5277, 5312,
    5321, 5323, 5333, 5345, 5349, 5355, 5381, 5394, 5404, 5411, 5425, 5429, 5446, 5483, 5485, 5493, 5495, 5500, 5517,
    5533, 5547, 5558, 5586, 5601, 5602, 5605, 5618, 5626, 5627, 5633, 5635, 5659, 5668, 5680, 5701, 5734, 5735, 5737,
    5741, 5747, 5752, 5763, 5770, 5782, 5797, 5798, 5810, 5811, 5834, 5844, 5855, 5869, 5877, 5887, 5891, 5903, 5911,
    5912, 5917, 5944, 5946, 5951, 5965, 5980, 6015, 6039, 6041, 6050, 6066, 6104, 6107, 6154, 6165, 6169, 6193, 6195,
    6198, 6201, 6221, 6229, 6232, 6247, 6252, 6273, 6278, 6283, 6288, 6291, 6293, 6298, 6304, 6310, 6316, 6318, 6326,
    6328, 6342, 6355, 6372, 6384, 6388, 6390, 6400, 6428, 6443, 6464, 6469, 6475, 6478, 6480, 6497, 6502, 6506, 6541,
    6544, 6547, 6552, 6558, 6562, 6568, 6595, 6623, 6630, 6656, 6664, 6678, 6699, 6742, 6768, 6770, 6778, 6792, 6806,
    6811, 6815, 6822, 6824, 6829, 6837, 6838, 6841, 6843, 6853, 6854, 6860, 6874, 6876, 6883, 6887, 6889, 6905, 6907,
    6910, 6968, 6978, 6993, 7003, 7004, 7011, 7034, 7046, 7101, 7104, 7121, 7127, 7142, 7147, 7154, 7157, 7165, 7170,
    7177, 7201, 7203, 7244, 7255, 7275, 7283, 7289, 7291, 7292, 7298, 7316, 7345, 7357, 7406, 7435, 7442, 7451, 7466,
    7468, 7472, 7477, 7500, 7523, 7542, 7564, 7571, 7576, 7582, 7588, 7590, 7596, 7620, 7622, 7631, 7646, 7662, 7689,
    7711, 7726, 7743, 7796, 7804, 7823, 7838, 7865, 7873, 7874, 7879, 7883, 7889, 7908, 7913, 7922, 7931, 7947, 7966,
    7972, 7984, 7992, 7993, 8007, 8021, 8035, 8037, 8040, 8054, 8055, 8065, 8072, 8075, 8089, 8097, 8107, 8115, 8117,
    8124, 8131, 8132, 8161, 8206, 8220, 8223, 8226, 8247, 8250, 8286, 8336, 8387, 8406, 8421, 8423, 8461, 8489, 8496,
    8505, 8512, 8523, 8526, 8528, 8552, 8554, 8561, 8569, 8574, 8581, 8592, 8603, 8616, 8646, 8695, 8705, 8713, 8723,
    8724, 8747, 8797, 8798, 8803, 8825, 8828, 8845, 8866, 8877, 8884, 8907, 8912, 8926, 8929, 8943, 8957, 8963, 8996,
    9017, 9026, 9029, 9048, 9054, 9061, 9064, 9077, 9092, 9097, 9113, 9133, 9143, 9152, 9176, 9188, 9209, 9216, 9218,
    9222, 9226, 9246, 9256, 9258, 9263, 9266, 9268, 9275, 9276, 9282, 9294, 9306, 9311, 9356, 9368, 9369, 9375, 9379,
    9383, 9417, 9441, 9454, 9457, 9487, 9494, 9504, 9508, 9513, 9515, 9521, 9522, 9531, 9538, 9554, 9587, 16, 42, 43,
    47, 50, 64, 66, 76, 78, 127, 130, 138, 182, 198,
  ];

  for (const element of LEMON_YELLOW) {
    colorMap.set(Number(element), 'LEMON_YELLOW');
  }

  let LIME_GREEN = [
    18, 26, 55, 67, 131, 143, 208, 318, 320, 373, 408, 440, 458, 540, 577, 596, 642, 751, 798, 834, 841, 871, 896, 928,
    929, 948, 984, 1067, 1100, 1110, 1122, 1136, 1160, 1242, 1261, 1271, 1274, 1293, 1316, 1323, 1347, 1471, 1516, 1519,
    1522, 1546, 1571, 1586, 1609, 1626, 1631, 1686, 1784, 1786, 1818, 1873, 1882, 1912, 1934, 1981, 1998, 2088, 2174,
    2193, 2195, 2209, 2214, 2221, 2241, 2369, 2376, 2386, 2397, 2419, 2466, 2467, 2523, 2547, 2575, 2598, 2600, 2616,
    2634, 2704, 2720, 2777, 2831, 2849, 2902, 2919, 2964, 2970, 2981, 2983, 3007, 3025, 3162, 3166, 3175, 3208, 3270,
    3356, 3429, 3462, 3508, 3537, 3569, 3667, 3729, 3867, 3877, 3991, 4114, 4186, 4188, 4196, 4254, 4284, 4340, 4407,
    4438, 4448, 4454, 4499, 4526, 4563, 4566, 4570, 4649, 4654, 4713, 4720, 4742, 4764, 4804, 4815, 4870, 4912, 4928,
    4950, 4972, 5035, 5048, 5056, 5173, 5204, 5217, 5223, 5257, 5352, 5358, 5361, 5388, 5420, 5439, 5443, 5463, 5496,
    5530, 5574, 5578, 5609, 5614, 5620, 5636, 5665, 5682, 5717, 5800, 5806, 5848, 5923, 5925, 5928, 5945, 5952, 5958,
    6037, 6060, 6072, 6114, 6121, 6122, 6141, 6191, 6205, 6230, 6241, 6267, 6314, 6315, 6334, 6382, 6412, 6441, 6466,
    6467, 6494, 6546, 6578, 6597, 6655, 6681, 6698, 6718, 6745, 6755, 6784, 6788, 6833, 6862, 6898, 6980, 6996, 7024,
    7027, 7050, 7072, 7075, 7088, 7109, 7110, 7116, 7174, 7179, 7185, 7191, 7216, 7248, 7299, 7322, 7341, 7375, 7398,
    7433, 7476, 7524, 7587, 7615, 7649, 7683, 7684, 7710, 7794, 7800, 7836, 7860, 7866, 7896, 7899, 7903, 7927, 7934,
    7940, 7975, 7976, 8048, 8091, 8126, 8148, 8182, 8210, 8219, 8221, 8266, 8271, 8275, 8283, 8284, 8358, 8377, 8390,
    8464, 8495, 8524, 8583, 8590, 8615, 8659, 8726, 8731, 8754, 8770, 8790, 8826, 8830, 8841, 8880, 8906, 8914, 8946,
    8988, 9032, 9065, 9098, 9156, 9199, 9217, 9232, 9257, 9310, 9320, 9329, 9374, 9393, 9433, 9435, 9448, 9466, 9481,
    9559, 8, 12,
  ];

  for (const element of LIME_GREEN) {
    colorMap.set(Number(element), 'LIME_GREEN');
  }

  let MAGENTA_PINK = [
    419, 444, 447, 460, 547, 571, 602, 604, 616, 619, 673, 688, 691, 706, 714, 717, 733, 760, 774, 793, 852, 883, 914,
    945, 966, 982, 993, 1019, 1055, 1068, 1081, 1086, 1101, 1109, 1170, 1248, 1249, 1252, 1260, 1295, 1300, 1354, 1371,
    1373, 1374, 1379, 1396, 1442, 1448, 1476, 1510, 1545, 1582, 1611, 1648, 1658, 1666, 1700, 1746, 1751, 1790, 1801,
    1807, 1865, 1872, 1874, 1907, 1919, 1952, 1986, 2003, 2006, 2008, 2018, 2044, 2052, 2072, 2082, 2108, 2133, 2153,
    2222, 2230, 2254, 2315, 2327, 2392, 2445, 2493, 2497, 2605, 2611, 2617, 2620, 2761, 2770, 2816, 2826, 2828, 2844,
    2858, 2874, 2896, 2897, 2955, 2993, 3036, 3069, 3189, 3194, 3209, 3254, 3260, 3302, 3340, 3378, 3379, 3407, 3425,
    3430, 3486, 3513, 3577, 3612, 3633, 3641, 3737, 3744, 3878, 3883, 3888, 3903, 3912, 3944, 3989, 4110, 4174, 4232,
    4245, 4262, 4301, 4311, 4418, 4498, 4515, 4568, 4599, 4673, 4697, 4704, 4750, 4769, 4829, 4831, 4838, 4844, 4867,
    4929, 4982, 4997, 5010, 5020, 5039, 5080, 5131, 5141, 5192, 5214, 5242, 5254, 5269, 5292, 5376, 5438, 5489, 5604,
    5632, 5634, 5698, 5713, 5724, 5781, 5830, 5873, 5874, 5889, 5895, 5931, 5968, 5987, 6001, 6042, 6046, 6062, 6087,
    6142, 6262, 6299, 6356, 6402, 6408, 6489, 6557, 6589, 6613, 6660, 6665, 6667, 6765, 6771, 6816, 6855, 6911, 7002,
    7087, 7098, 7129, 7132, 7148, 7155, 7158, 7196, 7220, 7228, 7233, 7293, 7305, 7335, 7409, 7426, 7430, 7458, 7473,
    7485, 7494, 7507, 7544, 7562, 7577, 7578, 7635, 7650, 7657, 7718, 7790, 7877, 7911, 7935, 7961, 8110, 8116, 8173,
    8200, 8207, 8265, 8267, 8310, 8405, 8416, 8589, 8625, 8671, 8702, 8792, 8878, 8899, 8904, 8913, 8970, 8977, 8985,
    9012, 9050, 9055, 9073, 9088, 9154, 9201, 9260, 9312, 9408, 9483, 9489, 9502, 9514, 9520, 9523, 9546, 9549, 9550,
    9572, 9581, 54, 59, 101, 121, 197, 201, 246, 258, 261, 303, 351, 380, 383, 388,
  ];

  for (const element of MAGENTA_PINK) {
    colorMap.set(Number(element), 'MAGENTA_PINK');
  }

  let MUSTARD_YELLOW = [
    686, 695, 797, 891, 947, 968, 1056, 1098, 1161, 1172, 1195, 1273, 1286, 1290, 1306, 1342, 1397, 1435, 1461, 1515,
    1523, 1524, 1533, 1655, 1671, 1693, 1897, 1898, 1922, 1950, 1955, 1956, 2067, 2078, 2100, 2102, 2184, 2236, 2249,
    2251, 2306, 2362, 2364, 2371, 2421, 2524, 2582, 2585, 2629, 2699, 2718, 2723, 2727, 2728, 2731, 2732, 2757, 2773,
    2805, 2894, 3019, 3074, 3120, 3140, 3213, 3281, 3283, 3296, 3308, 3349, 3367, 3534, 3544, 3555, 3558, 3560, 3580,
    3592, 3611, 3678, 3704, 3722, 3730, 3920, 3959, 3972, 4001, 4087, 4104, 4231, 4246, 4250, 4260, 4296, 4336, 4383,
    4413, 4425, 4464, 4538, 4544, 4630, 4675, 4913, 4965, 5004, 5005, 5012, 5031, 5085, 5106, 5107, 5114, 5243, 5246,
    5255, 5293, 5308, 5342, 5456, 5540, 5621, 5650, 5699, 5725, 5768, 5886, 5922, 5985, 6047, 6079, 6179, 6369, 6376,
    6383, 6504, 6625, 6674, 6736, 6764, 6804, 6840, 6945, 7008, 7030, 7036, 7071, 7143, 7188, 7235, 7281, 7294, 7344,
    7449, 7478, 7512, 7521, 7584, 7598, 7611, 7661, 7754, 7779, 7833, 7840, 7895, 8156, 8159, 8162, 8168, 8319, 8346,
    8457, 8467, 8473, 8487, 8529, 8551, 8660, 8721, 8808, 8867, 8941, 8954, 9091, 9170, 9187, 9252, 9357, 9361, 9366,
    9391, 9477, 9532, 9548, 9557, 1, 15, 142, 144, 276, 322, 363, 553, 574, 578, 584, 585, 589, 630,
  ];

  for (const element of MUSTARD_YELLOW) {
    colorMap.set(Number(element), 'MUSTARD_YELLOW');
  }

  let NEON_BLUE = [
    940, 1073, 1147, 1332, 1337, 1360, 1454, 1472, 1486, 1507, 1539, 1579, 1590, 1650, 1741, 1754, 1869, 1883, 1899,
    1902, 1932, 1969, 2026, 2073, 2094, 2130, 2295, 2335, 2340, 2402, 2537, 2544, 2635, 2666, 2752, 2756, 2785, 2850,
    2866, 2867, 2934, 2952, 3116, 3163, 3165, 3206, 3279, 3288, 3383, 3398, 3431, 3449, 3495, 3527, 3599, 3618, 3665,
    3719, 3764, 3800, 3802, 4031, 4084, 4100, 4125, 4229, 4233, 4332, 4337, 4371, 4380, 4386, 4396, 4493, 4524, 4585,
    4603, 4653, 4756, 4842, 4881, 4903, 4927, 4931, 5064, 5096, 5146, 5148, 5213, 5219, 5224, 5231, 5322, 5375, 5378,
    5424, 5441, 5452, 5468, 5492, 5521, 5584, 5606, 5687, 5777, 5799, 5885, 6004, 6024, 6034, 6040, 6168, 6187, 6219,
    6225, 6235, 6246, 6271, 6282, 6331, 6370, 6375, 6380, 6395, 6598, 6626, 6827, 6845, 6935, 7054, 7162, 7166, 7229,
    7262, 7395, 7421, 7456, 7496, 7502, 7522, 7566, 7568, 7621, 7644, 7645, 7685, 7698, 7703, 7731, 7745, 7846, 7851,
    7867, 7986, 8047, 8053, 8093, 8151, 8203, 8231, 8243, 8276, 8300, 8362, 8409, 8520, 8598, 8629, 8719, 8759, 8763,
    8772, 8810, 8833, 8843, 8937, 8949, 8953, 9031, 9316, 9330, 9336, 9364, 9419, 9422, 9453, 9503, 9566, 4, 82, 85, 91,
    97, 147, 151, 165, 169, 227, 333, 338, 365, 427, 432, 434, 487, 552, 582, 659, 763, 785, 843, 848, 897, 921,
  ];

  for (const element of NEON_BLUE) {
    colorMap.set(Number(element), 'NEON_BLUE');
  }

  let NEON_PURPLE = [
    495, 652, 656, 657, 658, 707, 710, 711, 762, 766, 768, 777, 781, 805, 817, 872, 877, 885, 890, 902, 932, 951, 959,
    970, 980, 1049, 1053, 1076, 1102, 1104, 1120, 1126, 1180, 1216, 1281, 1304, 1348, 1352, 1358, 1386, 1415, 1420,
    1430, 1463, 1465, 1490, 1552, 1570, 1659, 1673, 1704, 1724, 1727, 1740, 1748, 1749, 1761, 1779, 1794, 1810, 1816,
    1859, 1870, 1927, 1935, 1953, 1967, 2001, 2017, 2034, 2049, 2055, 2068, 2127, 2172, 2181, 2185, 2218, 2226, 2233,
    2235, 2256, 2272, 2329, 2330, 2425, 2438, 2444, 2505, 2561, 2567, 2619, 2631, 2640, 2710, 2772, 2884, 2886, 2891,
    2935, 2951, 3000, 3013, 3016, 3021, 3028, 3048, 3050, 3112, 3132, 3146, 3185, 3195, 3210, 3221, 3299, 3301, 3327,
    3374, 3386, 3395, 3403, 3412, 3417, 3433, 3443, 3475, 3518, 3547, 3557, 3562, 3608, 3622, 3662, 3687, 3710, 3720,
    3749, 3754, 3757, 3786, 3811, 3827, 3855, 3871, 3895, 3901, 3906, 3917, 3918, 3933, 3949, 3962, 3971, 3983, 4012,
    4072, 4086, 4147, 4148, 4150, 4152, 4161, 4213, 4226, 4243, 4272, 4278, 4321, 4347, 4353, 4357, 4363, 4369, 4381,
    4393, 4408, 4440, 4461, 4465, 4470, 4473, 4496, 4510, 4539, 4546, 4582, 4583, 4618, 4643, 4661, 4679, 4683, 4685,
    4705, 4721, 4724, 4744, 4773, 4776, 4779, 4785, 4788, 4818, 4822, 4825, 4849, 4862, 4887, 4908, 4911, 4917, 4959,
    4966, 4980, 4987, 4994, 5013, 5023, 5057, 5072, 5100, 5110, 5130, 5140, 5164, 5179, 5202, 5221, 5238, 5251, 5278,
    5283, 5291, 5301, 5314, 5363, 5368, 5370, 5382, 5401, 5419, 5470, 5472, 5477, 5488, 5501, 5509, 5561, 5564, 5667,
    5669, 5676, 5778, 5846, 5858, 5866, 5893, 5898, 5907, 5918, 5959, 5964, 5971, 5974, 5988, 5992, 6017, 6027, 6045,
    6048, 6051, 6073, 6076, 6080, 6092, 6099, 6103, 6126, 6130, 6132, 6150, 6164, 6176, 6181, 6203, 6259, 6261, 6290,
    6295, 6306, 6307, 6320, 6409, 6439, 6442, 6448, 6461, 6462, 6481, 6491, 6519, 6534, 6538, 6551, 6555, 6605, 6634,
    6637, 6639, 6641, 6691, 6710, 6721, 6733, 6774, 6777, 6787, 6805, 6809, 6818, 6842, 6844, 6849, 6895, 6896, 6915,
    6927, 6932, 6942, 6947, 6967, 6997, 7039, 7081, 7093, 7150, 7206, 7208, 7219, 7247, 7252, 7259, 7304, 7306, 7331,
    7333, 7347, 7369, 7410, 7414, 7437, 7445, 7482, 7489, 7498, 7519, 7586, 7606, 7686, 7744, 7775, 7852, 7901, 7904,
    7949, 7974, 7991, 8023, 8026, 8028, 8062, 8077, 8165, 8166, 8187, 8194, 8259, 8342, 8360, 8363, 8366, 8388, 8439,
    8440, 8445, 8471, 8475, 8506, 8511, 8515, 8536, 8550, 8556, 8566, 8596, 8648, 8656, 8706, 8715, 8720, 8751, 8793,
    8801, 8851, 8879, 8900, 8908, 8932, 8950, 8981, 8997, 9000, 9059, 9093, 9108, 9109, 9129, 9134, 9148, 9157, 9178,
    9197, 9206, 9211, 9255, 9277, 9290, 9307, 9314, 9339, 9345, 9371, 9395, 9407, 9429, 9442, 9450, 9490, 9493, 9510,
    9540, 9574, 9576, 13, 22, 24, 32, 35, 46, 71, 98, 160, 162, 228, 234, 249, 260, 263, 264, 265, 267, 317, 344, 350,
    361, 391, 409, 426, 448, 489,
  ];

  for (const element of NEON_PURPLE) {
    colorMap.set(Number(element), 'NEON_PURPLE');
  }

  let NEON_RED = [
    854, 905, 1280, 1335, 1346, 1449, 1469, 1504, 1526, 1543, 1715, 1789, 1803, 2051, 2372, 2390, 2673, 2755, 2921,
    2932, 3055, 3186, 3245, 3380, 3397, 3439, 3570, 3637, 3657, 3677, 3682, 3705, 3746, 3792, 4062, 4079, 4081, 4376,
    4439, 4508, 4514, 4531, 4698, 4710, 4719, 4799, 4805, 5091, 5162, 5208, 5247, 5351, 5372, 5457, 5481, 5544, 5670,
    5696, 5780, 5993, 6052, 6167, 6194, 6404, 6533, 6642, 6701, 6740, 6767, 6973, 7076, 7111, 7159, 7189, 7215, 7535,
    7540, 7627, 7633, 7639, 7697, 7701, 7938, 7939, 7945, 7954, 8030, 8164, 8196, 8309, 8322, 8371, 8442, 8456, 8478,
    8955, 9014, 9015, 9037, 9119, 9159, 9180, 9220, 9243, 9472, 52, 56, 65, 79, 232, 307, 334, 374, 392, 413, 478, 767,
    809, 831,
  ];

  for (const element of NEON_RED) {
    colorMap.set(Number(element), 'NEON_RED');
  }

  let ORANGE_ORANGE = [
    358, 371, 385, 406, 412, 422, 452, 455, 497, 502, 579, 592, 594, 599, 693, 703, 725, 758, 795, 816, 862, 888, 935,
    938, 972, 997, 1032, 1057, 1065, 1091, 1123, 1151, 1236, 1237, 1246, 1254, 1312, 1330, 1339, 1378, 1395, 1494, 1521,
    1559, 1600, 1602, 1605, 1628, 1633, 1639, 1644, 1654, 1674, 1736, 1778, 1799, 1812, 1831, 1923, 1928, 1964, 1978,
    1987, 2040, 2137, 2145, 2154, 2158, 2203, 2204, 2252, 2263, 2280, 2299, 2314, 2347, 2349, 2356, 2428, 2436, 2455,
    2463, 2486, 2511, 2520, 2526, 2531, 2535, 2566, 2579, 2596, 2622, 2658, 2668, 2688, 2729, 2742, 2789, 2793, 2804,
    2809, 2811, 2818, 2835, 2845, 2854, 2880, 2937, 2954, 2975, 3010, 3032, 3046, 3047, 3076, 3080, 3101, 3136, 3153,
    3204, 3224, 3230, 3238, 3261, 3264, 3278, 3304, 3345, 3385, 3387, 3452, 3477, 3522, 3539, 3587, 3601, 3639, 3723,
    3769, 3801, 3813, 3816, 3849, 3865, 3894, 3927, 3932, 3965, 3973, 3997, 4027, 4054, 4089, 4120, 4123, 4146, 4198,
    4217, 4236, 4273, 4313, 4444, 4452, 4481, 4494, 4497, 4503, 4537, 4545, 4555, 4559, 4622, 4646, 4650, 4726, 4757,
    4777, 4793, 4798, 4866, 4878, 4914, 4947, 5030, 5052, 5069, 5082, 5102, 5169, 5176, 5209, 5244, 5267, 5272, 5274,
    5286, 5287, 5295, 5324, 5350, 5357, 5386, 5389, 5412, 5430, 5445, 5450, 5494, 5498, 5525, 5534, 5554, 5557, 5617,
    5619, 5638, 5657, 5660, 5674, 5695, 5702, 5703, 5710, 5716, 5720, 5730, 5760, 5771, 5783, 5805, 5816, 5819, 5822,
    5825, 5863, 5868, 5920, 5976, 5981, 6019, 6036, 6113, 6144, 6156, 6173, 6255, 6260, 6336, 6346, 6394, 6410, 6421,
    6445, 6472, 6487, 6498, 6505, 6525, 6563, 6564, 6569, 6588, 6610, 6631, 6632, 6633, 6644, 6651, 6706, 6724, 6732,
    6747, 6762, 6791, 6820, 6850, 6861, 6885, 6891, 6963, 6998, 7014, 7044, 7074, 7115, 7173, 7197, 7207, 7224, 7226,
    7231, 7266, 7285, 7303, 7336, 7343, 7352, 7363, 7383, 7393, 7402, 7422, 7431, 7438, 7450, 7461, 7469, 7513, 7518,
    7520, 7555, 7614, 7720, 7732, 7736, 7764, 7768, 7776, 7810, 7815, 7826, 7887, 7894, 7920, 8050, 8099, 8105, 8123,
    8145, 8160, 8191, 8193, 8209, 8288, 8294, 8340, 8349, 8359, 8373, 8374, 8382, 8396, 8433, 8454, 8474, 8500, 8510,
    8540, 8639, 8662, 8665, 8667, 8692, 8740, 8742, 8762, 8795, 8870, 8883, 8960, 8972, 8989, 8992, 9045, 9046, 9053,
    9094, 9127, 9149, 9177, 9181, 9192, 9204, 9386, 9398, 9424, 9451, 9478, 9499, 9512, 9529, 9537, 9539, 9560, 9597, 9,
    14, 17, 140, 171, 175, 183, 188, 230, 243, 280, 295, 340, 356,
  ];

  for (const element of ORANGE_ORANGE) {
    colorMap.set(Number(element), 'ORANGE_ORANGE');
  }

  let PASTEL_ORANGE = [
    330, 332, 339, 352, 396, 400, 414, 506, 513, 529, 534, 542, 586, 593, 629, 668, 674, 694, 729, 731, 789, 791, 821,
    832, 857, 859, 892, 934, 952, 961, 1014, 1016, 1063, 1071, 1097, 1107, 1116, 1129, 1230, 1243, 1270, 1283, 1322,
    1338, 1340, 1341, 1368, 1375, 1385, 1390, 1445, 1473, 1502, 1513, 1534, 1542, 1610, 1614, 1638, 1645, 1661, 1675,
    1703, 1711, 1783, 1824, 1828, 1853, 1866, 1904, 1929, 1940, 1961, 2014, 2035, 2039, 2050, 2061, 2074, 2103, 2105,
    2106, 2107, 2169, 2189, 2191, 2288, 2297, 2323, 2346, 2358, 2432, 2470, 2474, 2484, 2485, 2498, 2536, 2568, 2569,
    2587, 2592, 2594, 2657, 2722, 2724, 2751, 2763, 2792, 2820, 2824, 2852, 2865, 2876, 2881, 2901, 2904, 2917, 2941,
    2971, 2974, 2987, 3008, 3009, 3022, 3033, 3034, 3041, 3044, 3057, 3063, 3064, 3070, 3077, 3125, 3129, 3130, 3147,
    3202, 3220, 3233, 3247, 3252, 3287, 3290, 3293, 3306, 3326, 3330, 3353, 3355, 3357, 3376, 3408, 3423, 3446, 3487,
    3491, 3540, 3567, 3568, 3586, 3617, 3623, 3624, 3636, 3640, 3656, 3689, 3707, 3715, 3734, 3774, 3806, 3817, 3853,
    3869, 3876, 3891, 3904, 3942, 3950, 3979, 3982, 3987, 3990, 3998, 4000, 4014, 4016, 4023, 4024, 4050, 4073, 4102,
    4134, 4144, 4154, 4166, 4167, 4170, 4172, 4179, 4214, 4221, 4230, 4256, 4263, 4286, 4287, 4297, 4318, 4345, 4350,
    4373, 4404, 4409, 4422, 4431, 4432, 4520, 4543, 4550, 4556, 4557, 4633, 4700, 4701, 4716, 4718, 4819, 4824, 4833,
    4879, 4889, 4898, 4915, 4941, 4952, 4977, 5040, 5053, 5071, 5086, 5111, 5115, 5122, 5125, 5133, 5158, 5160, 5166,
    5194, 5197, 5206, 5215, 5226, 5240, 5290, 5319, 5320, 5332, 5365, 5369, 5371, 5374, 5415, 5465, 5466, 5482, 5487,
    5506, 5507, 5580, 5590, 5644, 5679, 5697, 5704, 5758, 5762, 5775, 5790, 5808, 5821, 5908, 5924, 5947, 5977, 6055,
    6089, 6096, 6109, 6136, 6138, 6143, 6160, 6171, 6175, 6177, 6188, 6223, 6233, 6250, 6256, 6300, 6322, 6327, 6333,
    6339, 6350, 6352, 6401, 6407, 6429, 6436, 6447, 6451, 6471, 6511, 6514, 6518, 6535, 6617, 6647, 6668, 6669, 6680,
    6684, 6689, 6690, 6697, 6704, 6705, 6715, 6717, 6760, 6769, 6780, 6793, 6881, 6882, 6888, 6923, 6952, 6970, 6988,
    7000, 7037, 7047, 7051, 7053, 7070, 7073, 7084, 7091, 7227, 7263, 7264, 7287, 7326, 7355, 7371, 7384, 7388, 7391,
    7432, 7490, 7543, 7548, 7563, 7604, 7637, 7641, 7647, 7656, 7669, 7679, 7702, 7739, 7742, 7767, 7771, 7773, 7777,
    7812, 7828, 7885, 7886, 7916, 7946, 7950, 7953, 8006, 8009, 8012, 8046, 8058, 8061, 8071, 8074, 8104, 8128, 8134,
    8135, 8138, 8142, 8147, 8256, 8277, 8312, 8350, 8352, 8378, 8379, 8398, 8411, 8412, 8415, 8448, 8469, 8476, 8482,
    8497, 8518, 8537, 8544, 8553, 8558, 8575, 8599, 8605, 8608, 8624, 8627, 8628, 8632, 8633, 8645, 8672, 8678, 8696,
    8728, 8778, 8811, 8814, 8835, 8838, 8849, 8858, 8893, 8910, 8928, 8967, 8979, 9013, 9019, 9040, 9052, 9100, 9103,
    9126, 9145, 9160, 9163, 9173, 9174, 9239, 9281, 9300, 9301, 9317, 9324, 9341, 9358, 9387, 9413, 9420, 9449, 9516,
    9527, 9558, 9584, 9585, 9596, 2, 33, 80, 118, 159, 164, 205, 215, 271, 277, 283, 296, 304, 316, 328,
  ];

  for (const element of PASTEL_ORANGE) {
    colorMap.set(Number(element), 'PASTEL_ORANGE');
  }

  let PASTEL_YELLOW = [
    1599, 1608, 1618, 1624, 1663, 1854, 1939, 1973, 2007, 2062, 2079, 2101, 2275, 2326, 2415, 2422, 2448, 2460, 2483,
    2488, 2500, 2578, 2581, 2602, 2623, 2744, 2768, 2783, 2822, 2895, 2929, 2990, 3075, 3096, 3110, 3173, 3244, 3248,
    3315, 3321, 3365, 3404, 3529, 3559, 3563, 3595, 3739, 3765, 3840, 3879, 3916, 4048, 4107, 4175, 4292, 4316, 4368,
    4429, 4435, 4519, 4554, 4592, 4665, 4680, 4749, 4883, 4884, 4901, 4905, 4923, 4985, 5067, 5135, 5156, 5184, 5229,
    5297, 5302, 5303, 5341, 5366, 5449, 5475, 5503, 5512, 5514, 5526, 5623, 5629, 5705, 5761, 5905, 5915, 5930, 5972,
    6007, 6065, 6151, 6199, 6319, 6444, 6570, 6579, 6599, 6607, 6723, 6856, 6916, 6929, 6992, 7012, 7031, 7040, 7125,
    7139, 7222, 7274, 7297, 7300, 7319, 7328, 7342, 7411, 7440, 7497, 7546, 7591, 7623, 7709, 7723, 7816, 7891, 7979,
    7981, 7998, 8178, 8181, 8248, 8282, 8305, 8325, 8426, 8427, 8468, 8498, 8541, 8564, 8582, 8584, 8587, 8636, 8701,
    8733, 8737, 8918, 9038, 9063, 9074, 9078, 9095, 9150, 9166, 9168, 9172, 9190, 9195, 9249, 9267, 9303, 9394, 9423,
    9438, 9463, 9518, 9551, 9562, 27, 29, 96, 195, 245, 256, 368, 372, 395, 416, 424, 488, 535, 564, 612, 704, 772, 824,
    828, 836, 838, 887, 981, 1000, 1009, 1026, 1045, 1143, 1159, 1214, 1361, 1394, 1399, 1414, 1424, 1475, 1541, 1589,
  ];

  for (const element of PASTEL_YELLOW) {
    colorMap.set(Number(element), 'PASTEL_YELLOW');
  }

  let PUMPKIN_ORANGE = [
    437, 456, 457, 464, 467, 469, 493, 521, 600, 607, 613, 614, 636, 666, 702, 735, 788, 923, 937, 985, 1011, 1013,
    1059, 1099, 1119, 1175, 1210, 1253, 1268, 1318, 1328, 1400, 1433, 1447, 1492, 1499, 1561, 1572, 1619, 1687, 1695,
    1697, 1732, 1742, 1744, 1756, 1773, 1813, 1829, 1846, 1860, 1864, 1910, 1951, 1988, 2015, 2113, 2122, 2178, 2187,
    2196, 2215, 2264, 2285, 2311, 2320, 2341, 2553, 2571, 2606, 2627, 2662, 2764, 2775, 2779, 2787, 2799, 2803, 2836,
    2869, 2877, 2908, 2918, 2959, 2972, 3015, 3037, 3085, 3106, 3124, 3143, 3205, 3212, 3331, 3393, 3409, 3416, 3481,
    3501, 3510, 3575, 3578, 3613, 3649, 3654, 3680, 3778, 3830, 3846, 3900, 3902, 3958, 3963, 3988, 4019, 4030, 4070,
    4085, 4106, 4210, 4216, 4225, 4349, 4375, 4467, 4504, 4523, 4564, 4600, 4645, 4666, 4676, 4687, 4715, 4745, 4748,
    4761, 4876, 4945, 4956, 4969, 5008, 5024, 5083, 5143, 5163, 5232, 5367, 5379, 5406, 5462, 5464, 5532, 5546, 5562,
    5575, 5585, 5597, 5600, 5642, 5721, 5738, 5745, 5751, 5814, 5860, 5867, 5882, 6000, 6009, 6031, 6032, 6082, 6084,
    6095, 6118, 6125, 6152, 6157, 6163, 6185, 6211, 6213, 6216, 6228, 6289, 6296, 6368, 6379, 6386, 6391, 6420, 6727,
    6744, 6807, 6835, 6936, 6940, 6941, 6961, 7062, 7135, 7160, 7186, 7209, 7221, 7230, 7253, 7279, 7325, 7329, 7348,
    7380, 7408, 7419, 7420, 7427, 7452, 7492, 7495, 7511, 7531, 7547, 7595, 7618, 7625, 7642, 7648, 7653, 7681, 7751,
    7793, 7839, 7943, 8025, 8064, 8070, 8090, 8120, 8127, 8188, 8246, 8314, 8327, 8332, 8345, 8356, 8369, 8381, 8477,
    8479, 8488, 8502, 8573, 8595, 8618, 8637, 8638, 8682, 8684, 8691, 8712, 8736, 8756, 8757, 8776, 8839, 8850, 8916,
    8921, 8934, 8942, 8995, 9021, 9023, 9030, 9044, 9081, 9099, 9118, 9123, 9139, 9144, 9153, 9215, 9230, 9273, 9289,
    9304, 9308, 9519, 9599, 23, 48, 84, 136, 145, 146, 166, 168, 191, 209, 259, 329, 367, 375,
  ];

  for (const element of PUMPKIN_ORANGE) {
    colorMap.set(Number(element), 'PUMPKIN_ORANGE');
  }

  let SKY_BLUE = [
    156, 172, 181, 213, 224, 244, 254, 255, 273, 274, 288, 294, 306, 308, 312, 319, 326, 337, 401, 410, 430, 433, 443,
    454, 466, 480, 492, 496, 505, 507, 509, 520, 531, 543, 545, 554, 563, 565, 569, 573, 576, 597, 608, 610, 622, 645,
    647, 650, 665, 675, 677, 678, 698, 716, 727, 742, 756, 765, 782, 811, 812, 814, 825, 829, 855, 860, 866, 869, 874,
    882, 886, 893, 909, 913, 916, 922, 946, 962, 963, 976, 978, 987, 989, 999, 1007, 1015, 1027, 1031, 1042, 1060, 1074,
    1080, 1082, 1084, 1090, 1115, 1127, 1128, 1133, 1140, 1153, 1166, 1171, 1174, 1178, 1181, 1187, 1199, 1200, 1202,
    1207, 1228, 1241, 1251, 1256, 1258, 1264, 1275, 1287, 1296, 1299, 1311, 1313, 1315, 1331, 1336, 1350, 1357, 1367,
    1401, 1404, 1412, 1427, 1429, 1438, 1467, 1480, 1493, 1506, 1511, 1512, 1518, 1564, 1565, 1580, 1584, 1585, 1587,
    1630, 1636, 1643, 1649, 1665, 1705, 1706, 1708, 1719, 1721, 1726, 1731, 1745, 1752, 1762, 1788, 1792, 1798, 1815,
    1820, 1825, 1832, 1851, 1880, 1881, 1884, 1886, 1889, 1892, 1970, 1971, 1995, 2000, 2011, 2021, 2038, 2054, 2065,
    2071, 2083, 2087, 2099, 2109, 2120, 2131, 2136, 2156, 2157, 2162, 2166, 2231, 2246, 2253, 2277, 2294, 2296, 2307,
    2308, 2393, 2400, 2413, 2429, 2447, 2453, 2468, 2473, 2479, 2499, 2502, 2507, 2514, 2525, 2527, 2530, 2533, 2534,
    2551, 2557, 2615, 2633, 2637, 2646, 2675, 2687, 2717, 2719, 2749, 2760, 2765, 2767, 2776, 2778, 2780, 2781, 2795,
    2823, 2837, 2856, 2860, 2862, 2873, 2889, 2913, 2915, 2938, 2940, 2953, 2956, 2958, 2962, 2965, 2996, 3030, 3040,
    3054, 3079, 3086, 3089, 3090, 3097, 3113, 3118, 3134, 3138, 3144, 3149, 3150, 3168, 3174, 3183, 3192, 3201, 3214,
    3222, 3227, 3235, 3239, 3255, 3266, 3277, 3292, 3320, 3323, 3333, 3343, 3346, 3351, 3352, 3354, 3358, 3363, 3366,
    3377, 3415, 3420, 3440, 3442, 3451, 3454, 3457, 3473, 3488, 3532, 3545, 3585, 3603, 3631, 3635, 3642, 3650, 3664,
    3688, 3702, 3726, 3728, 3743, 3755, 3756, 3758, 3794, 3795, 3798, 3807, 3826, 3832, 3835, 3854, 3880, 3885, 3898,
    3915, 3930, 3936, 3940, 3946, 3955, 3961, 3970, 3984, 3994, 3999, 4005, 4007, 4021, 4025, 4036, 4037, 4046, 4060,
    4090, 4094, 4099, 4112, 4141, 4163, 4168, 4176, 4177, 4181, 4185, 4189, 4190, 4195, 4205, 4207, 4211, 4227, 4228,
    4234, 4247, 4312, 4338, 4342, 4348, 4354, 4359, 4367, 4378, 4421, 4456, 4462, 4480, 4487, 4495, 4512, 4522, 4528,
    4533, 4534, 4535, 4542, 4552, 4553, 4571, 4578, 4581, 4591, 4598, 4602, 4609, 4610, 4615, 4629, 4631, 4636, 4651,
    4659, 4662, 4686, 4690, 4691, 4703, 4734, 4759, 4762, 4763, 4808, 4820, 4845, 4858, 4865, 4871, 4885, 4894, 4907,
    4920, 4933, 4942, 4943, 4964, 4984, 4989, 4990, 5003, 5011, 5021, 5042, 5050, 5066, 5068, 5073, 5094, 5112, 5129,
    5137, 5139, 5149, 5168, 5170, 5175, 5210, 5228, 5245, 5260, 5265, 5307, 5311, 5326, 5330, 5331, 5335, 5346, 5354,
    5360, 5364, 5373, 5387, 5409, 5426, 5461, 5491, 5504, 5531, 5539, 5553, 5567, 5572, 5573, 5583, 5610, 5625, 5641,
    5647, 5651, 5655, 5672, 5707, 5709, 5712, 5739, 5757, 5779, 5788, 5792, 5804, 5840, 5842, 5854, 5880, 5899, 5901,
    5916, 5955, 5960, 5970, 5973, 5989, 6006, 6013, 6018, 6023, 6033, 6057, 6078, 6093, 6097, 6102, 6108, 6110, 6129,
    6135, 6178, 6183, 6189, 6202, 6215, 6226, 6231, 6237, 6240, 6258, 6269, 6280, 6297, 6301, 6309, 6317, 6344, 6348,
    6354, 6367, 6373, 6377, 6396, 6403, 6406, 6411, 6413, 6431, 6437, 6455, 6485, 6486, 6521, 6522, 6523, 6529, 6531,
    6532, 6537, 6540, 6559, 6573, 6575, 6590, 6591, 6592, 6593, 6594, 6619, 6622, 6624, 6629, 6635, 6653, 6654, 6661,
    6675, 6682, 6707, 6725, 6729, 6754, 6756, 6761, 6776, 6789, 6794, 6819, 6821, 6846, 6867, 6869, 6894, 6900, 6909,
    6917, 6919, 6921, 6928, 6933, 6938, 6966, 6969, 6974, 6984, 6985, 6999, 7001, 7005, 7006, 7010, 7019, 7025, 7028,
    7043, 7048, 7057, 7058, 7083, 7086, 7095, 7096, 7106, 7107, 7119, 7134, 7137, 7138, 7144, 7152, 7161, 7163, 7169,
    7195, 7202, 7236, 7237, 7251, 7254, 7258, 7288, 7301, 7302, 7311, 7318, 7353, 7354, 7367, 7374, 7378, 7382, 7387,
    7389, 7394, 7397, 7400, 7416, 7417, 7441, 7447, 7479, 7491, 7493, 7509, 7514, 7515, 7526, 7537, 7561, 7569, 7592,
    7594, 7626, 7654, 7663, 7664, 7665, 7666, 7682, 7690, 7693, 7716, 7722, 7727, 7733, 7755, 7756, 7758, 7761, 7770,
    7772, 7781, 7782, 7788, 7821, 7827, 7859, 7861, 7897, 7900, 7915, 7926, 7942, 7944, 7960, 7963, 7967, 7969, 8027,
    8043, 8049, 8056, 8066, 8078, 8080, 8082, 8098, 8101, 8111, 8113, 8118, 8129, 8139, 8150, 8167, 8177, 8190, 8213,
    8215, 8216, 8234, 8237, 8245, 8251, 8253, 8254, 8262, 8268, 8269, 8281, 8293, 8296, 8298, 8324, 8337, 8343, 8372,
    8376, 8383, 8384, 8386, 8391, 8394, 8397, 8408, 8425, 8443, 8447, 8492, 8494, 8508, 8516, 8527, 8531, 8548, 8555,
    8563, 8579, 8604, 8611, 8621, 8626, 8634, 8640, 8655, 8666, 8674, 8690, 8725, 8761, 8771, 8779, 8782, 8805, 8807,
    8821, 8842, 8857, 8860, 8864, 8894, 8903, 8911, 8945, 8956, 8987, 9001, 9003, 9006, 9024, 9028, 9041, 9056, 9057,
    9060, 9082, 9084, 9112, 9114, 9120, 9131, 9135, 9185, 9193, 9205, 9210, 9212, 9228, 9235, 9264, 9280, 9286, 9288,
    9297, 9327, 9337, 9354, 9355, 9363, 9365, 9384, 9385, 9390, 9397, 9405, 9410, 9411, 9418, 9421, 9425, 9426, 9431,
    9437, 9447, 9458, 9460, 9461, 9462, 9471, 9491, 9496, 9517, 9543, 9547, 9556, 9564, 9567, 9578, 9586, 9589, 9590,
    9594, 9595, 6, 60, 62, 63, 73, 81, 86, 93, 103, 114, 119, 120, 122, 154,
  ];

  for (const element of SKY_BLUE) {
    colorMap.set(Number(element), 'SKY_BLUE');
  }

  let WINE_RED = [
    51, 102, 123, 189, 214, 221, 279, 299, 335, 384, 403, 418, 465, 503, 548, 660, 661, 671, 740, 745, 757, 803, 808,
    813, 820, 840, 861, 920, 939, 967, 986, 992, 1001, 1078, 1094, 1163, 1193, 1263, 1282, 1402, 1416, 1431, 1444, 1487,
    1491, 1548, 1558, 1569, 1588, 1694, 1734, 1830, 1837, 1926, 1931, 1936, 2009, 2027, 2036, 2090, 2095, 2141, 2142,
    2146, 2206, 2274, 2279, 2289, 2321, 2328, 2336, 2389, 2403, 2408, 2416, 2456, 2480, 2539, 2555, 2556, 2671, 2678,
    2700, 2708, 2766, 2802, 2807, 2830, 2840, 2853, 2859, 2879, 2925, 2961, 2963, 2978, 2980, 2984, 3012, 3035, 3052,
    3084, 3154, 3176, 3218, 3241, 3257, 3390, 3406, 3411, 3441, 3463, 3589, 3630, 3632, 3679, 3700, 3708, 3742, 3780,
    3784, 3793, 3838, 3872, 3893, 3897, 3929, 3937, 3938, 3947, 3964, 4092, 4156, 4165, 4266, 4289, 4298, 4322, 4329,
    4343, 4356, 4365, 4475, 4574, 4577, 4597, 4621, 4628, 4669, 4708, 4755, 4791, 4806, 4813, 4826, 4846, 4847, 4854,
    5033, 5157, 5236, 5253, 5310, 5421, 5431, 5435, 5454, 5497, 5527, 5550, 5579, 5637, 5656, 5666, 5718, 5743, 5765,
    5796, 5839, 5847, 5856, 5875, 5921, 5935, 5953, 5984, 5998, 6011, 6075, 6111, 6123, 6139, 6210, 6251, 6266, 6277,
    6284, 6312, 6359, 6393, 6405, 6473, 6484, 6488, 6492, 6501, 6510, 6571, 6612, 6749, 6772, 6799, 6826, 6847, 6863,
    6877, 6971, 6979, 7007, 7009, 7021, 7035, 7041, 7059, 7065, 7100, 7140, 7151, 7156, 7232, 7270, 7271, 7346, 7453,
    7471, 7480, 7488, 7525, 7527, 7541, 7575, 7581, 7672, 7673, 7678, 7715, 7740, 7786, 7955, 7964, 7982, 7987, 7990,
    8004, 8015, 8039, 8041, 8079, 8095, 8122, 8225, 8233, 8235, 8273, 8289, 8290, 8292, 8297, 8303, 8339, 8357, 8400,
    8450, 8459, 8462, 8521, 8543, 8576, 8597, 8619, 8651, 8657, 8668, 8683, 8693, 8732, 8739, 8760, 8769, 8915, 8920,
    8925, 8965, 8983, 9009, 9083, 9124, 9155, 9175, 9182, 9184, 9251, 9262, 9298, 9326, 9349, 9396, 9400, 9444, 9474,
    9582, 11, 39,
  ];

  for (const element of WINE_RED) {
    colorMap.set(Number(element), 'WINE_RED');
  }

  return true;
};
