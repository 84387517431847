import React, { Component } from 'react';
import '.././App.css';
import { useHistory } from 'react-router-dom';

export const Legal = () => {
  let history = useHistory();
  return (
    <div className="LegalSection">
      <div className="LegalContent">
        <a onClick={() => history.goBack()} class="btn-floating btn-large waves-effect waves-light blue lighten-3">
          <i class="material-icons">arrow_back</i>
        </a>
        <h2 className="center-align">NFT License</h2>
        <h4>1. Definitions.</h4>
        <p>
          “Art” means any art, design, and drawings that may be associated with an NFT that you Own. "NFT" means any
          blockchain-tracked, non-fungible token, such as those conforming to the ERC-721 standard. “Own” means, with
          respect to an NFT, an NFT that you have purchased or otherwise rightfully acquired from a legitimate source,
          where proof of such purchase is recorded on the relevant blockchain. “Extensions” means third party designs
          that: (i) are intended for use as extensions or overlays to the Art, (ii) do not modify the underlying Art,
          and (iii) can be removed at any time without affecting the underlying Art. “Purchased NFT” means an NFT that
          you Own. “Third Party IP” means any third-party patent rights (including, without limitation, patent
          applications and disclosures), copyrights, trade secrets, trademarks, know-how or any other intellectual
          property rights recognized in any country or jurisdiction in the world.
        </p>
        <h4>2. Ownership.</h4>
        <p>
          You acknowledge and agree that Bradley Hart Studio (“BHS” or, as applicable, its licensors) owns all legal
          right, title, and interest in and to the Art, and all intellectual property rights therein. The rights that
          you have in and to the Art are limited to those described in this License. BHS reserves all rights in and to
          the Art not expressly granted to you in this License.
        </p>
        <h4>3. License.</h4>
        <p>
          a. General Use. Subject to your continued compliance with the terms of this License, BHS grants you a
          worldwide, non-exclusive, non-transferable, royalty-free license to use and display the Art included in your
          Purchased NFT(s), along with any Extensions that you choose to create or use, solely for the following
          purposes: (i) for your own personal, non-commercial use; (ii) as part of a marketplace that permits the
          purchase and sale of your NFTs, provided that the marketplace cryptographically verifies each NFT owner’s
          rights to display the Art for their Purchased NFTs to ensure that only the actual owner can display the Art;
          or (iii) as part of a third party website or application that permits the inclusion, involvement, or
          participation of your NFTs, provided that the website/application cryptographically verifies each NFT owner’s
          rights to display the Art for their Purchased NFTs to ensure that only the actual owner can display the Art,
          and provided that the Art is no longer visible once the owner of the Purchased NFT leaves the
          website/application.
        </p>
        <p>
          b. Commercial Use. BHS grants no license to purchasers to use Purchased NFTs for any commercial purpose. Such
          uses include, but are not limited to, the purpose of commercializing your own merchandise that includes,
          contains, or consists of the Art for your Purchased NFTs (“Commercial Use”). For the sake of clarity, nothing
          in this Section 3.b will be deemed to restrict you from (i) owning or operating a marketplace that permits the
          use and sale of NFTs generally, provided that the marketplace cryptographically verifies each NFT owner’s
          rights to display the Art for their Purchased NFTs to ensure that only the actual owner can display the Art;
          (ii) owning or operating a third party website or application that permits the inclusion, involvement, or
          participation of NFTs generally, provided that the third party website or application cryptographically
          verifies each NFT owner’s rights to display the Art for their Purchased NFTs to ensure that only the actual
          owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased
          NFT(s).
        </p>
        <p>
          c. Upon request, Purchased NFTs’ original purchaser will be provided with a JPEG image of their respective
          Poppable as it appeared prior to popping or self-destructing (“unpopped poppables”). These unpopped poppables
          images may be used in derivative works by the owner insofar as the original Purchased NFT is then owned by the
          creator at the time of the creation of the derivative work. There is no limitation on the number of unpopped
          poppables you may use, assuming the aforementioned conditions are met.
        </p>
        <h4>4. Restrictions.</h4>
        <p>
          You agree that you may not, nor permit any third party to do or attempt to do any of the foregoing without
          BHS’s express prior written consent in each case: (i) modify or copy the Art in your Purchased NFT in any way,
          including, without limitation, the shapes, designs, drawings, attributes, or color schemes (your use of
          Extensions will not constitute a prohibited modification hereunder); (ii) use the Art for your Purchased NFTs
          to advertise, market, or sell any third party product or service; (iii) use the Art for your Purchased NFTs in
          connection with images, videos, or other forms of media that depict hatred, intolerance, violence, cruelty, or
          anything else that could reasonably be found to constitute hate speech or otherwise infringe upon the rights
          of others; (iv) use the Art for your Purchased NFTs in movies, videos, or any other forms of media, except to
          the limited extent that such use is expressly permitted in Section 3(b) above or solely for your own personal,
          non-commercial use; (v) sell, distribute for commercial gain (including, without limitation, giving away in
          the hopes of eventual commercial gain), or otherwise commercialize merchandise that includes, contains, or
          consists of the Art for your Purchased NFTs, except as expressly permitted in Section 3(b) above; (vi) attempt
          to trademark, copyright, or otherwise acquire additional intellectual property rights in or to the Art for
          your Purchased NFTs; or (vii) otherwise utilize the Art for your Purchased NFTs for your or any third party’s
          commercial benefit. The restriction in Section 4 shall survive any expiration or termination of this License.
        </p>
        <h4>5. Terms of License.</h4>
        <p>
          The license granted in Section 3 above applies only to the extent that you continue to Own the applicable
          Purchased NFT. If at any time you sell, trade, donate, give away, transfer, or otherwise dispose of your
          Purchased NFT, for any reason, the license granted in Section 3 will immediately expire with respect to those
          NFTs without the requirement of notice, and you will have no further rights in or to the Art for those NFTs.
          If you are in breach of this License, you must send an email to BHS at poppableprojects@gmail.com within
          fifteen (15) days, with the phrase “NFT License - Commercial Use” in the subject line, requesting a discussion
          with BHS regarding entering into a broader license agreement or obtaining an exemption (which may be granted
          or withheld in BHS’s sole and absolute discretion). If you exceed the scope of the license grant in Section
          3.b without entering into a broader license agreement or obtaining an exemption from BHS, you acknowledge and
          agree that: (i) you are in breach of this License; (ii) in addition to any remedies that may be available to
          BHS at law or in equity, the BHS may immediately terminate this License, without the requirement of notice;
          and (iii) you will be responsible to reimburse BHS for any costs and expenses incurred by BHS during the
          course of enforcing the terms of this License against you, including attorney’s fees.
        </p>
      </div>
    </div>
  );
};

export default Legal;
