import React, { Component } from 'react';
import LandingPage from './components/LandingPage';
import MyPoppablePasses from './components/MyPoppablePasses';
import Legal from './components/Legal';
import Guidelines from './components/CollectorGuidelines';
import { BrowserRouter, Route } from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/mypoppablepasses" component={MyPoppablePasses} />
          <Route exact path="/legal" component={Legal} />
          <Route exact path="/guidelines" component={Guidelines} />
          <Route
            path="/discord"
            component={() => {
              window.location.href = 'https://discord.gg/t55m4KY77k';
              return null;
            }}
          />
          <Route
            path="/twitter"
            component={() => {
              window.location.href = 'https://twitter.com/PoppableProject';
              return null;
            }}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
