import React, { Component } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import Confetti from 'react-confetti';
import { CustomView, isBrowser, isTablet, MobileView } from 'react-device-detect';
import _ from 'lodash';
import '.././App.css';
import Sidebar from './Sidebar';
import ReactPlayer from 'react-player';
import M from 'materialize-css/dist/js/materialize.min.js';
import 'materialize-css/dist/css/materialize.min.css';
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
  getCurrentUserPoppables,
  savePoppedImage,
  getAllContractTokens,
} from '../util/interact.js';

// const images = [
//   'BLACK',
//   'BLUEBERRY_BLUE',
//   'BRASS',
//   'BUBBLEGUM_PINK',
//   'CANDY_BLUSH_PINK',
//   'CHROME',
//   'CLEAR',
//   'EMERALD_GREEN',
//   'GOLD',
//   'GRAPE_PURPLE',
//   'GRASS_GREEN',
//   'HOT_SAUCE_RED',
//   'LAVENDER_PURPLE',
//   'LEMON_YELLOW',
//   'LIME_GREEN',
//   'MAGENTA_PINK',
//   'MUSTARD_YELLOW',
//   'NEON_BLUE',
//   'NEON_PURPLE',
//   'NEON_RED',
//   'ORANGE_ORANGE',
//   'PASTEL_ORANGE',
//   'PASTEL_YELLOW',
//   'PUMPKIN_ORANGE',
//   'SKY_BLUE',
//   'WINE_RED',
// ];

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.onScroll = this.onScroll.bind(this);

    this.clickImage = this.clickImage.bind(this);

    this.state = {
      isVisible: false,
      walletAddress: '',
      ether: '0.05 ETH',
      radioSelected: 0,
      saleNotification: '',
      walletMessage: 'Connected: ',
      poppingcentreimage: 'images/pc_stamp.png',
      playing: false,
      poppingVideoHidden: 'ElementHidden',
      poppingImageHidden: '',
      poppedImageHidden: 'ElementHidden',
      poppedVideoHidden: 'ElementHidden',
      popButton: 'images/pc_button2.png',
      mypoppableUpoppedStatement: 'ElementHidden',
      mypoppableStatement: 'ElementHidden',
      playingUniqueBubble: false,
      activeIndex: -1,
      messageHiiden: 'ElementHidden',
      message: '',
      walletPoppables: '',
      isActiveIndex: -1,
      poppingInfo: '',
      loading: false,
      poppedImage: '',
      poppedVideoURL: '',
      isUnpoppable: false,
      images: [],
      status: '',
      totalNFTs: 46,
    };
  }

  //1600 - totalNFTs} Poppables Left
  //Total Project: 422 + totalNFTs

  addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          this.setState({ walletAddress: accounts[0] });
          this.setState({ status: '👆🏽 Write a message in the text-field above.' });
        } else {
          this.setState({ walletAddress: '' });
          this.setState({ status: '🦊 Connect to Metamask using the top right button.' });
        }
      });
    } else {
      this.setState({
        status: (
          <p>
            {' '}
            🦊{' '}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your browser.
            </a>
          </p>
        ),
      });
    }
  }

  async videoEnded() {
    if (this.state.isUnpoppable) {
      this.setState({
        poppingVideoHidden: 'ElementHidden',
        poppingImageHidden: 'ElementHidden',
        poppedImageHidden: 'ElementHidden',
        mypoppableStatement: '',
        poppedVideoHidden: '',
        playingUniqueBubble: true,
        mypoppableUpoppedStatement: '',
        isActiveIndex: -1,
      });
    } else {
      this.setState({
        poppingVideoHidden: 'ElementHidden',
        poppingImageHidden: 'ElementHidden',
        poppedImageHidden: '',
        mypoppableStatement: '',
        mypoppableUpoppedStatement: 'ElementHidden',
        poppedVideoHidden: 'ElementHidden',
        isActiveIndex: -1,
      });
    }

    //set to default
    this.setState({ isUnpoppable: false });

    const poppablesResponse = await getCurrentUserPoppables(this.state.walletAddress);

    var currentImages = [];
    for (const element of poppablesResponse) {
      currentImages.push(element.token_color);
    }

    this.setState({
      walletPoppables: poppablesResponse,
      images: currentImages,
    });
  }

  async popImage() {
    this.setState({
      message: '',
    });
    //NOTHING TO POP
    if (this.state.walletPoppables.length == 0) {
      this.setState({
        messageHiiden: '',
        message: 'No poppables to pop',
      });
    } else {
      //POPPING
      this.setState({ loading: true });
      const popResult = await savePoppedImage(this.state.poppingInfo);
      this.setState({ loading: false });

      var imageURL = popResult.data;

      if (imageURL == 'unpoppable_BLACK') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/BLACKMULTI.mp4',
        });
      } else if (imageURL == 'unpoppable_BLUEBERRY_BLUE') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/BLUEBERRYBLUE.mp4',
        });
      } else if (imageURL == 'unpoppable_BRASS') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'ihttps://poppablesproject.s3.amazonaws.com/BRASS.mp4',
        });
      } else if (imageURL == 'unpoppable_BUBBLEGUM_PINK') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/BUBBLEGUMPINK.mp4',
        });
      } else if (imageURL == 'unpoppable_CANDY_BLUSH_PINK') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/CANDYBLUSHPINK.mp4',
        });
      } else if (imageURL == 'unpoppable_CHROME') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/CHROMEMULTI.mp4',
        });
      } else if (imageURL == 'unpoppable_CLEAR') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/CLEARBUBBLE.mp4',
        });
      } else if (imageURL == 'unpoppable_EMERALD_GREEN') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/FORESTGREEN.mp4',
        });
      } else if (imageURL == 'unpoppable_GOLD') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/GOLDMULTI.mp4',
        });
      } else if (imageURL == 'unpoppable_GRAPE_PURPLE') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/GRAPEPURPLE.mp4',
        });
      } else if (imageURL == 'unpoppable_GRASS_GREEN') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/GRASSGREEN.mp4',
        });
      } else if (imageURL == 'unpoppable_HOT_SAUCE_RED') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/HOTSAUCERED.mp4',
        });
      } else if (imageURL == 'unpoppable_LAVENDER_PURPLE') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/LAVENDERPURPLE.mp4',
        });
      } else if (imageURL == 'unpoppable_LEMON_YELLOW') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/LEMONYELLOW.mp4',
        });
      } else if (imageURL == 'unpoppable_LIME_GREEN') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/LIMEGREEN.mp4',
        });
      } else if (imageURL == 'unpoppable_MAGENTA_PINK') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/MAGENTAPINKMULTI.mp4',
        });
      } else if (imageURL == 'unpoppable_MUSTARD_YELLOW') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/MUSTARDYELLOWMULTI.mp4',
        });
      } else if (imageURL == 'unpoppable_NEON_BLUE') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/NEONBLUEMULTI.mp4',
        });
      } else if (imageURL == 'unpoppable_NEON_PURPLE') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/NEONPURPLEMULTI.mp4',
        });
      } else if (imageURL == 'unpoppable_NEON_RED') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/NEONREDMULTI.mp4',
        });
      } else if (imageURL == 'unpoppable_ORANGE_ORANGE') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/ORANGEORANGEMULTI.mp4',
        });
      } else if (imageURL == 'unpoppable_PASTEL_ORANGE') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/PASTELORANGEMULTI.mp4',
        });
      } else if (imageURL == 'unpoppable_PASTEL_YELLOW') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/PastelYellow.mp4',
        });
      } else if (imageURL == 'unpoppable_PUMPKIN_ORANGE') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/PUMPKINORANGEMULTI.mp4',
        });
      } else if (imageURL == 'unpoppable_SKY_BLUE') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/SBMULTI.mp4',
        });
      } else if (imageURL == 'unpoppable_WINE_RED') {
        this.setState({ isUnpoppable: true });
        this.setState({
          poppedVideoURL: 'https://poppablesproject.s3.amazonaws.com/WINEREDMULTI.mp4',
        });
      } else {
        this.setState({ poppedImage: imageURL });
      }
      console.log('Pop Result: ', imageURL);

      this.setState({
        playing: true,
        poppingVideoHidden: '',
        poppingImageHidden: 'ElementHidden',
        poppedVideoHidden: 'ElementHidden',
        popButton: 'images/pc_button1.png',
      });
    }
  }

  clickImage(image, ix) {
    //set to default
    this.setState({
      playing: false,
      playingUniqueBubble: false,
      poppingVideoHidden: 'ElementHidden',
      poppingImageHidden: 'ElementHidden',
      poppedVideoHidden: 'ElementHidden',
      popButton: 'images/pc_button2.png',
      poppingImageHidden: '',
      poppedImageHidden: 'ElementHidden',
      mypoppableStatement: 'ElementHidden',
      mypoppableUpoppedStatement: 'ElementHidden',
    });

    // console.log('IX', ix);
    let token_id = this.state.walletPoppables[ix].token_id;
    let realTokenID = this.state.walletPoppables[ix].real_token_id;
    let token_color = this.state.walletPoppables[ix].token_color;
    let wallet_address = this.state.walletAddress;

    this.setState({
      messageHiiden: '',
      message: 'Token ID: ' + token_id,
      isActiveIndex: ix,
      poppingInfo: {
        token_id: token_id,
        realTokenID: realTokenID,
        token_color: token_color,
        wallet_address: wallet_address,
      },
    });

    // console.log('Image is clicked', image);
    if (image == 'BLACK') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/stamp_black.jpg',
      });
    } else if (image == 'BLUEBERRY_BLUE') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/stamp_blueberry_blue.jpg',
      });
    } else if (image == 'BRASS') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/stamp_brass.jpg',
      });
    } else if (image == 'BUBBLEGUM_PINK') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/stamp_bubblegum_pink.jpg',
      });
    } else if (image == 'CANDY_BLUSH_PINK') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/stamp_candy_blush_pink.jpg',
      });
    } else if (image == 'CHROME') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/stamp_chrome.jpg',
      });
    } else if (image == 'CLEAR') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/stamp_clear.jpg',
      });
    } else if (image == 'EMERALD_GREEN') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_emerald_green.jpg',
      });
    } else if (image == 'GOLD') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_gold.jpg',
      });
    } else if (image == 'GRAPE_PURPLE') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_grape_purple.jpg',
      });
    } else if (image == 'GRASS_GREEN') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_grass_green.jpg',
      });
    } else if (image == 'HOT_SAUCE_RED') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_hot_sause_red.jpg',
      });
    } else if (image == 'LAVENDER_PURPLE') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_lavender_purple.jpg',
      });
    } else if (image == 'LEMON_YELLOW') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_lemon_yellow.jpg',
      });
    } else if (image == 'LIME_GREEN') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_lime_green.jpg',
      });
    } else if (image == 'MAGENTA_PINK') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_magenta_pink.jpg',
      });
    } else if (image == 'MUSTARD_YELLOW') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_mustard_yellow.jpg',
      });
    } else if (image == 'NEON_BLUE') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_neon_blue.jpg',
      });
    } else if (image == 'NEON_PURPLE') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_neon_purple.jpg',
      });
    } else if (image == 'NEON_RED') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_neon_red.jpg',
      });
    } else if (image == 'ORANGE_ORANGE') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_orange_orange.jpg',
      });
    } else if (image == 'PASTEL_ORANGE') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_pastel_orange.jpg',
      });
    } else if (image == 'PASTEL_YELLOW') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_pastel_yellow.jpg',
      });
    } else if (image == 'PUMPKIN_ORANGE') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_pumpkin_orange.jpg',
      });
    } else if (image == 'SKY_BLUE') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_sky_blue.jpg',
      });
    } else if (image == 'WINE_RED') {
      this.setState({
        poppingcentreimage: 'images/press_unpoppables/pc_wine_red.jpg',
      });
    } else {
      this.setState({
        poppingcentreimage: 'images/pc_stamp.png',
      });
    }

    // console.log(this.state.images);
    // const images = update(this.state.images, {
    //   [ix]: { $apply: (x) => ({ active: !x.active }) },
    // });
    // this.setState({ images });
  }

  handleWalletChange(wAddress) {
    this.setState({
      walletAddress: wAddress,
    });
  }

  // async connectWallet() {
  //   const { address } = await getCurrentWalletConnected();
  //   this.handleWalletChange(address);

  //   console.log('Debug point 32897472983');

  //   const poppablesResponse = await getCurrentUserPoppables(address);

  //   if (window.ethereum) {
  //     window.ethereum.on('accountsChanged', (accounts) => {
  //       if (accounts.length > 0) {
  //         this.handleWalletChange(accounts[0]);
  //       } else {
  //         this.handleWalletChange('');
  //       }
  //     });
  //   }
  // }

  async connectWalletPressed() {
    const walletResponse = await connectWallet();

    const poppablesResponse = await getCurrentUserPoppables(walletResponse.address);

    var currentImages = [];
    for (const element of poppablesResponse) {
      currentImages.push(element.token_color);
    }

    this.setState({
      walletPoppables: poppablesResponse,
      images: currentImages,
    });

    this.handleWalletChange(walletResponse.address);
    if (walletResponse.alertMessage.trim() === 'Already processing eth_requestAccounts. Please wait.') {
      // console.log('Wallet Connection Failed. Please reload the page.');
    } else if (walletResponse.alertMessage.trim().length === 0) {
    } else {
      console.log(walletResponse.alertMessage);
    }

    this.getUserSettings();
  }

  processImageSelected(imagedata) {
    console.log('Image selection is triggered', imagedata);
    // var fullPath = document.getElementById('img1').src;
  }

  async mint() {
    let pAmount = 0;
    let ether = this.state.ether;
    if (ether.toString() == '0.05 ETH') {
      pAmount = 1;
    } else if (ether.toString() == '0.1 ETH') {
      pAmount = 2;
    } else if (ether.toString() == '0.15 ETH') {
      pAmount = 3;
    } else if (ether.toString() == '0.2 ETH') {
      pAmount = 4;
    } else if (ether.toString() == '0.25 ETH') {
      pAmount = 5;
    } else if (ether.toString() == '0.3 ETH') {
      pAmount = 6;
    } else if (ether.toString() == '0.35 ETH') {
      pAmount = 7;
    } else if (ether.toString() == '0.4 ETH') {
      pAmount = 8;
    } else if (ether.toString() == '0.45 ETH') {
      pAmount = 9;
    } else if (ether.toString() == '0.5 ETH') {
      pAmount = 10;
    } else if (ether.toString() == '0.55 ETH') {
      pAmount = 11;
    } else if (ether.toString() == '0.6 ETH') {
      pAmount = 12;
    } else if (ether.toString() == '0.65 ETH') {
      pAmount = 13;
    } else if (ether.toString() == '0.7 ETH') {
      pAmount = 14;
    } else if (ether.toString() == '0.75 ETH') {
      pAmount = 15;
    } else if (ether.toString() == '0.8 ETH') {
      pAmount = 16;
    } else if (ether.toString() == '0.85 ETH') {
      pAmount = 17;
    } else if (ether.toString() == '0.9 ETH') {
      pAmount = 18;
    } else if (ether.toString() == '0.95 ETH') {
      pAmount = 19;
    } else if (ether.toString() == '1 ETH') {
      pAmount = 20;
    } else if (ether.toString() == '1.05 ETH') {
      pAmount = 21;
    } else if (ether.toString() == '1.1 ETH') {
      pAmount = 22;
    }

    await mintNFT('SaleNFT', this.state.ether);
    //TODO test here
    // const isDoneSuccessfully = await mintNFT('SaleNFT', this.state.ether);
    // if (isDoneSuccessfully.success) {
    //   const totalNFTs = await getAllContractTokens();
    //   this.setState({
    //     totalNFTs: totalNFTs,
    //   });
    // }
  }

  qtyUpdated(selectedAmount) {
    if (selectedAmount == 1) {
      this.setState({ ether: '0.05 ETH' });
    } else if (selectedAmount == 2) {
      this.setState({ ether: '0.1 ETH' });
    } else if (selectedAmount == 3) {
      this.setState({ ether: '0.15 ETH' });
    } else if (selectedAmount == 4) {
      this.setState({ ether: '0.2 ETH' });
    } else if (selectedAmount == 5) {
      this.setState({ ether: '0.25 ETH' });
    } else if (selectedAmount == 6) {
      this.setState({ ether: '0.3 ETH' });
    } else if (selectedAmount == 7) {
      this.setState({ ether: '0.35 ETH' });
    } else if (selectedAmount == 8) {
      this.setState({ ether: '0.4 ETH' });
    } else if (selectedAmount == 9) {
      this.setState({ ether: '0.45 ETH' });
    } else if (selectedAmount == 10) {
      this.setState({ ether: '0.5 ETH' });
    } else if (selectedAmount == 11) {
      this.setState({ ether: '0.55 ETH' });
    } else if (selectedAmount == 12) {
      this.setState({ ether: '0.6 ETH' });
    } else if (selectedAmount == 13) {
      this.setState({ ether: '0.65 ETH' });
    } else if (selectedAmount == 14) {
      this.setState({ ether: '0.7 ETH' });
    } else if (selectedAmount == 15) {
      this.setState({ ether: '0.75 ETH' });
    } else if (selectedAmount == 16) {
      this.setState({ ether: '0.8 ETH' });
    } else if (selectedAmount == 17) {
      this.setState({ ether: '0.85 ETH' });
    } else if (selectedAmount == 18) {
      this.setState({ ether: '0.9 ETH' });
    } else if (selectedAmount == 19) {
      this.setState({ ether: '0.95 ETH' });
    } else if (selectedAmount == 20) {
      this.setState({ ether: '1 ETH' });
    } else if (selectedAmount == 21) {
      this.setState({ ether: '1.05 ETH' });
    } else if (selectedAmount == 22) {
      this.setState({ ether: '1.1 ETH' });
    }
  }

  async getUserSettings() {}

  async componentDidMount() {
    // this.connectWalletPressed();

    const { address } = await getCurrentWalletConnected();
    this.handleWalletChange(address);

    const poppablesResponse = await getCurrentUserPoppables(address);

    var currentImages = [];
    for (const element of poppablesResponse) {
      currentImages.push(element.token_color);
    }

    const totalNFTs = await getAllContractTokens();

    this.setState({
      walletPoppables: poppablesResponse,
      images: currentImages,
      totalNFTs: totalNFTs,
    });

    //walletMessage
    if (window.innerWidth <= 768) {
      this.setState({ walletMessage: '' });
    }

    window.addEventListener('scroll', this.onScroll);

    var elem = document.querySelector('select');
    var instance = M.FormSelect.init(elem, {});

    var elems = document.querySelectorAll('.dropdown-trigger');
    var instances = M.Dropdown.init(elems, {});

    this.addWalletListener();
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    let heightToHideFrom = 250;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      this.setState({ isVisible: true });
    } else {
      this.setState({ isVisible: false });
    }
  };

  render() {
    let poppableImage;
    if (this.state.isVisible) {
      poppableImage = (
        <div className="ImagePoppableLogo">
          <img className="responsive-img" src="images/poppables-width300.png" alt="logo hill scene main" />
        </div>
      );
    } else {
      poppableImage = <div></div>;
    }
    return (
      <div className="content z-depth-1 scrollspy" id="Home">
        <div className="topDiv">
          <div className="row">
            <div className="col s2 m4 ">
              <div className="NavigationBarLeft">
                <a href="#" data-target="slide-out" className="sidenav-trigger">
                  <i className="material-icons small Hamburger">menu</i>
                </a>
              </div>
            </div>
            <div className="col s2 m4 ">{poppableImage}</div>
            <div className="col s8 m4  right-align">
              <div className="NavigationBarRight">
                <a
                  href="/#"
                  className="button waves-effect  waves-light"
                  onClick={this.connectWalletPressed.bind(this)}
                >
                  <i className="material-icons left">account_balance_wallet</i>
                  {this.state.walletAddress.length > 0
                    ? this.state.walletMessage +
                      String(this.state.walletAddress).substring(0, 6) +
                      '...' +
                      String(this.state.walletAddress).substring(38)
                    : 'Connect Wallet'}
                </a>
              </div>
            </div>
          </div>
        </div>
        <Sidebar />

        {/* MAIN POPPABLE SCENE HERE */}

        <div className="MainPoppableScene">
          <picture>
            <source media="(min-width: 650px)" srcSet="images/scene.jpg" />
            <source media="(min-width: 375px)" srcSet="images/scene-mobile-375.jpg" />
            <img className="responsive-img" src="images/scene.jpg" alt="logo hill scene" />
          </picture>
          <div className="row">
            <div className="col s12 m6">
              <div className="GifImage">
                <img className="responsive-img" src="images/gif/poppables_250ms.gif" alt="gif poppables" />
              </div>
            </div>
            <div className="col s12 m6">
              <div className="MintingBoxContainer">
                <div className="row">
                  <div className="col s12 ">
                    <h5 className="h">Purchase Poppables</h5>
                  </div>

                  <div className="col s12">
                    <p>Mint up to 22 per transaction</p>
                  </div>
                  <div className="col s8">
                    <p className="FontSizeGeneral">
                      Buy Poppables <br /> 0.05 ETH/each <br /> (gas is not included)
                    </p>
                  </div>

                  <div className="input-field col s4">
                    <select id="select1" onChange={(e) => this.qtyUpdated(e.target.value)}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                    </select>
                  </div>

                  <div className="col s6">
                    <p className="FontSizeGeneral">Total Due</p>
                  </div>
                  <div className="col s6">
                    <p className="FontSizeGeneral text-align-right">{this.state.ether}</p>
                  </div>

                  <div className="col s12">
                    <div className="mintButton">
                      <a onClick={this.mint.bind(this)} className=" blue lighten-3 waves-effect  waves-light btn-large">
                        Mint
                      </a>
                    </div>
                  </div>

                  <div className="col s12">
                    <div>
                      <p className="PoppableLeftSection">{+1600 - +this.state.totalNFTs} Poppables Left</p>
                      <p className="BoldText">Total Project: {+422 + +this.state.totalNFTs}/2022</p>
                    </div>
                  </div>

                  <div className="col s12">
                    <div>
                      <p className="PoppableLeftSectionDistruction">
                        You have 24 hours to pop your poppable until it self destructs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col s12">
            <div className="ProjectDescriptionSection">
              <h3 className="center-align Font">PROJECT DESCRIPTION</h3>
              <p className="FontSize center-align">PHASE TWO </p>{' '}
              <p className="FontSize">
                POPPABLES is a 2022 two-phase interactive generative NFT collection living on the Ethereum blockchain.
              </p>
              <p className="FontSize">
                Phase One is for 422 early collectors. These 422 Common Poppables have the potential to acquire OG
                status when popped. When popped they are imbued with the power to change a common Poppable in Phase Two
                to og2 status during the mint. Each Phase has its own smart contract but the project's rarities will be
                calculated across both contracts.
              </p>
              <p className="FontSize">
                Phase Two is comprised of the remaining 1600 Poppables and they as well as the Phase One tokens have
                progressive utility broken down into multiple stages throughout the projects journey. The Injection
                Process is where you will mint your Poppable. This Poppable will be special and unique to you but part
                of a bigger picture. When popping season begins, get in line to pop your Poppable. Share and compare
                your Poppable Passes. Collecting matching pairs, different or rare combinations and sets of Poppable
                Passes will benefit collectors with merchandise and unique rewards. Lastly possession of a POPPABLE PASS
                will provide access to take part in future Poppable Projects as well as specific Bradley Hart events,
                metaverse experiences and NFT drops.
              </p>
            </div>
          </div>
        </div>

        <div className="PaddingPoppingStation" id="sectionPopping">
          <h3 className="center-align Font scrollspy">THE POPPING STATION</h3>
          <picture>
            <source media="(min-width: 650px)" srcSet="images/popping-centre.jpg" />
            <source media="(min-width: 375px)" srcSet="images/popping-centre-mobile.jpg" />
            <img className="responsive-img" src="images/popping-centre.jpg" alt="popping centre info" />
          </picture>
        </div>

        <div className="Padding">
          <p>
            Welcome to the Popping station. Once Popping Season begins this is where you will pop your Poppable. Follow
            the steps below to start popping.
          </p>
          <div className="TextPadding">
            <p>
              1. Connect your wallet. Once your wallet is connected your Poppables will populate the My Poppables list.
            </p>
            <p>2. Select the Poppable you wish to pop and it will show up on the platform.</p>
            <p>
              3. Select the POP button and watch as your Poppable Pass is revealed. If you have more than one Poppable,
              repeat step 2 and 3 until your Poppables are popped.
            </p>
            <p>4. You can view your Poppable Pass in the “My Poppable Passes” page in the drop down menu.</p>
          </div>
        </div>

        {/* MOBILE OR DESKTOP custom view is used because of the bug for iPad detection*/}
        <CustomView condition={isBrowser && !isTablet}>
          <div className="StickySections">
            <div className="flex-container">
              <div className="child">
                <div className="MyPoppableImageContainer">
                  <p className="hide-on-small-only">My Poppables</p>
                  <div className="MyPoppableImageContainerInside">
                    <div>
                      {this.state.images.map((image, ix) => (
                        <img
                          className={`MyPoppableImage ${
                            this.state.isActiveIndex === ix ? 'ImageSelected' : 'ImageUnSelected'
                          }`}
                          key={ix}
                          src={`images/unpopped/${image}.jpg`}
                          alt="Unpopped Default Image"
                          onClick={() => this.clickImage(image, ix)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="child">
                <div className="MyPoppableStationStairsImageDivSize">
                  <div className="MyPoppableStationPopButtonImageHover">
                    <img
                      className="MyPoppableStationPopButtonImage"
                      src={this.state.popButton}
                      alt="Popping Station Button"
                      onClick={this.popImage.bind(this)}
                    />
                  </div>
                  <div className="Loader">
                    <BeatLoader color="red" loading={this.state.loading} size={10} />
                  </div>
                  <div>
                    <img
                      className="MyPoppableStationStairsImage"
                      src="images/pc_stairs.png"
                      alt="Popping Station Stairs"
                    />
                  </div>
                </div>
              </div>
              <div className="child">
                <div className="MyPoppableStationImageDivSize">
                  <div className={this.state.poppingImageHidden}>
                    <img
                      className="MyPoppableStationImage"
                      src={this.state.poppingcentreimage}
                      alt="Popping Station Unrevealed"
                    />
                  </div>
                  <div className={this.state.poppingVideoHidden}>
                    <ReactPlayer
                      url="images/stamp/StampV8.mp4"
                      width="315px"
                      height="446px"
                      playsinline={true}
                      controls={false}
                      muted={false}
                      playing={this.state.playing}
                      onEnded={this.videoEnded.bind(this)}
                    />
                  </div>
                  <div className={this.state.poppedImageHidden}>
                    <img
                      className="MyPoppableStationImage"
                      src={this.state.poppedImage}
                      alt="Popping Station Unrevealed"
                    />
                  </div>
                  <div className={this.state.poppedVideoHidden}>
                    <div>
                      <Confetti width="1200px" height="455px" />
                    </div>
                    <ReactPlayer
                      url={this.state.poppedVideoURL}
                      width="315px"
                      height="446px"
                      loop={true}
                      controls={false}
                      muted={false}
                      playing={this.state.playingUniqueBubble}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="Message">
              <p>{this.state.message}</p>
            </div>
            <div className={this.state.mypoppableUpoppedStatement}>
              <p className="center-align CongratulationsMessage">CONGRATULATIONS! YOUR POPPABLE IS UNPOPPABLE!</p>
            </div>
            <div className={this.state.mypoppableStatement}>
              <p className="center-align">
                Go to
                <a href="/mypoppablepasses"> My Poppable Passes </a>
                to see your popped poppables.
              </p>
            </div>
          </div>

          <div className="StickySections scrollspy scrollOffset">
            <div id="RoadMapSection" className="RoadMapSection">
              <div className="RoadMapContainer">
                <picture>
                  <source media="(min-width: 650px)" srcSet="images/road-map.jpg" />
                  <source media="(min-width: 375px)" srcSet="images/road-map-mobile.jpg" />
                  <img className="responsive-img" src="images/road-map.jpg" alt="road map" />
                </picture>

                <a
                  className="DetourContainer"
                  href="https://discord.com/channels/882288527902146612/882288529206571041"
                >
                  <img className="DetourImage" src="images/detour-button.png" altt="go to discord" />
                  <div className="ImageLabel">Continue to Discord</div>
                </a>
              </div>
            </div>
          </div>
        </CustomView>
        <MobileView>
          <div className="flex-container-mobile">
            <div className="child">
              <div className="MyPoppableImageContainer-mobile">
                <div className="MyPoppableImageContainerInside-mobile">
                  {this.state.images.map((image, ix) => (
                    <img
                      className={`MyPoppableImage-mobile ${
                        this.state.isActiveIndex === ix ? 'ImageSelected' : 'ImageUnSelected'
                      }`}
                      key={ix}
                      src={`images/unpopped/${image}.jpg`}
                      alt="Unpopped Default Image"
                      onClick={() => this.clickImage(image, ix)}
                    />
                  ))}
                </div>
              </div>

              <div>
                <img
                  className="MyPoppableStationStairsImage-mobile"
                  src="images/pc_stairs-mobile.png"
                  alt="Popping Station Stairs"
                />
              </div>

              <div className="MyPoppableStationImageDivSize-mobile">
                <div className={this.state.poppingImageHidden}>
                  <img
                    className="MyPoppableStationImage-mobile"
                    src={this.state.poppingcentreimage}
                    alt="Popping Station Unrevealed"
                  />
                </div>
                <div className={this.state.poppingVideoHidden}>
                  <ReactPlayer
                    url="images/stamp/StampV8.mp4"
                    width="97%"
                    height="100%"
                    playsinline={true}
                    controls={false}
                    muted={false}
                    playing={this.state.playing}
                    onEnded={this.videoEnded.bind(this)}
                  />
                </div>
                <div className={this.state.poppedImageHidden}>
                  <img
                    className="MyPoppableStationImage-mobile"
                    src={this.state.poppedImage}
                    alt="Popping Station Unrevealed"
                  />
                </div>
                <div className={this.state.poppedVideoHidden}>
                  <div>
                    <Confetti width="97%" height="100%" />
                  </div>
                  <ReactPlayer
                    url={this.state.poppedVideoURL}
                    width="97%"
                    height="100%"
                    loop={true}
                    controls={false}
                    muted={false}
                    playing={this.state.playingUniqueBubble}
                  />
                </div>
              </div>

              <div className="MyPoppableStationStairsImageDivSize-mobile">
                <div className="MyPoppableStationPopButtonImageHover-mobile">
                  <img
                    className="MyPoppableStationPopButtonImage-mobile"
                    src={this.state.popButton}
                    alt="Popping Station Button"
                    onClick={this.popImage.bind(this)}
                  />
                </div>
                <div className="Loader-mobile">
                  <BeatLoader color="red" loading={this.state.loading} size={10} />
                </div>
              </div>
            </div>

            <div className="Message-mobile">
              <p>{this.state.message}</p>
            </div>
            <div className={this.state.mypoppableUpoppedStatement}>
              <p className="center-align CongratulationsMessage">CONGRATULATIONS! YOUR POPPABLE IS UNPOPPABLE!</p>
            </div>
            <div className={this.state.mypoppableStatement}>
              <p className="center-align">
                Go to
                <a href="/mypoppablepasses"> My Poppable Passes </a>
                to see your popped poppables.
              </p>
            </div>
          </div>

          <div id="RoadMapSection">
            <div className="RoadMapSection-mobile">
              <div className="RoadMapContainer">
                <picture>
                  <source media="(min-width: 650px)" srcSet="images/road-map.jpg" />
                  <source media="(min-width: 375px)" srcSet="images/road-map-mobile.jpg" />
                  <img className="responsive-img" src="images/road-map.jpg" alt="road map" />
                </picture>

                <a
                  className="DetourContainer"
                  href="https://discord.com/channels/882288527902146612/882288529206571041"
                >
                  <img className="DetourImage" src="images/detour-button.png" altt="go to discord" />
                  <div className="ImageLabel">Continue to Discord</div>
                </a>
              </div>
            </div>
          </div>
        </MobileView>
      </div>
    );
  }
}

export default LandingPage;
